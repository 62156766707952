import React, { Component } from 'react';
import { isEmpty } from 'lodash';
import { Countries } from '../../consts/Countries';
import './step1.scss';

export default class Step1 extends Component {
	constructor(props) {
		super();
	}

	selectCountry = country => {
		const currentCountry = localStorage.getItem('country') || '';
		this.props.handleChange({
			name: 'country',
			value: country
		});

		if (!isEmpty(currentCountry) && currentCountry === country) {
			return;
		}

		// wipe city if a different country is selected
		this.props.handleChange({
			name: 'city',
			value: ''
		});
	};

	render() {
		const { country } = this.props;

		return (
			<div id="step-1" className="main-container">
				<div className="main-title-container">
					<div className="step-container">
						<div className="step">
							<span>1</span>
						</div>
					</div>
					<div className="title-container">
						<h2>Tell us where you are traveling</h2>
					</div>
				</div>
				<div className="options-container">
					{Countries.map(item => {
						return (
							<div
								key={item.value}
								className={
									country === ''
										? 'country-box'
										: item.value === country
										? 'country-box active'
										: 'country-box inactive'
								}
								onClick={() => {
									this.selectCountry(item.value);
								}}
								style={{
									backgroundImage: 'url(' + item.img + ')'
								}}
							>
								<p>{item.value}</p>
							</div>
						);
					})}
				</div>
			</div>
		);
	}
}
