export const InterestList = {
	'3DjxSqsAfFvDgvJJpxiM': 'Activities for solo travelers',
	'8UekcweFxqBmuBCfW468': 'Religious sites',
	Ei95oyO6kPQntGX0qRnO: 'Surfing and Other Water Sports',
	DQoQMSAjltFfjB1p3uqN: 'Wildlife and Nature',
	D5eeFRpD7fx9s8Y4v49x: 'Bars and Nightlife',
	DpKQ3Ldh7DymDGidQXlu: 'Photography',
	F81caNGGs6Frd62i9qt4: 'Restaurants And Local Food',
	HiPMLgADzGjV7i4oFmmu: 'Food and Cooking',
	Gw7ktFK4PzCT6mcsRDV4: 'Local Artisans and Markets',
	HRMeXmCi4lC5gCHs4KxY: 'Architecture',
	K44Q1NrhIsB8C0CTIoWS: 'Cooking',
	t0yBSawiUs5cumRvZ8Nz: 'Wellness and Exercise',
	QHiLPQzOHNue9ixM6fXB: 'Shopping and Fashion',
	KB67tdt57U2QQrSCSrcu: 'Unique & Unusual Places',
	P3ghJW1Za8G29kvc87py: 'Sporting Events',
	QcbZbhVyxc3vXUS6oHOd: 'Ethical Travel and Volunteering',
	vaHYsgHjVvO0zAcXrzmi: 'Major Sites, Architecture and Orientation',
	MPFfI1RKzee8r0tp8FUf: 'Historical and Religious Sites',
	MlLpwYavOtrk226VX2px: 'Art, Culture and Museums',
	Se3wMYRSTOmoFTjzZfaI: 'Major sites / orientation',
	VDDbuYoYUIMtK9jsFDNj: 'History and historical landmarks',
	f0lL8XvpmU5z0H70z7sx: 'Family and kids activities',
	lvohKqckBE5jf8Gn6tGZ: 'Cultural sites',
	vXgDdB1UuBoOGoFTxnmJ: 'Galleries and museums',
	JQrfgqO4ZEW4mmgMQBEg: 'Hiking and Walks'
};
