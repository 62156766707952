export default {
	USER_CREATED: 'UserCreated',
	USER_LOADED: 'UserLoaded',
	USER_UPDATED: 'UserUpdated',
	USERS_FILTERED: 'UsersFiltered',
	PROFILE_LOADED: 'ProfileLoaded',
	BOOKING_LOADED: 'BookingLoaded',
	ITINERARY_LOADED: 'ItineraryLoaded',
	BOOKING_CREATED: 'BookingCreated',
	ITINERARY_CREATED: 'ItineraryCreated',
	ITINERARY_SAVED: 'ItinerarySaved',
	PURCHASE_COMPLETED: 'PurchaseCompleted',
	BOOKING_UPDATED: 'BookingUpdated',
	AVAILABILITY_UPDATED: 'AvailabilityUpdated',
	ITINERARIES_LOADED: 'ItinerariesLoaded',
	CHAT_LOADED: 'ChatLoaded',
	MESSAGE_CREATED: 'MessageCreated',
	REVIEW_LOADED: 'ReviewLoaded',
	REVIEW_UPDATED: 'ReviewUpdated',
	REVIEWS_LOADED: 'ReviewsLoaded',
	INTERESTS_LOADED: 'InterestsLoaded',

	// GRANULAR EVENTS
	USER_ADDED_TO_BOOKING: 'userAddedToBooking'
};
