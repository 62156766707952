import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { faStar as regularStart } from '@fortawesome/free-regular-svg-icons';

import Dispatcher from '../../common/dispatcher/index';
import ActionTypes from '../../common/actions';
import EventTypes from '../../common/events';
import UserStore from '../../common/stores/user';

import BtnLoader from '../utils/BtnLoader';

import defaultPhoto from '../../assets/default-placeholder.png';

class Review extends Component {
	constructor(props) {
		super();
		const itineraryId = props.match.params.id;
		const user = UserStore.getUser();
		const isAmbassador = user.isAmbassador;

		let reviewerId;
		let reviewedId;
		if (isAmbassador) {
			reviewedId = user.uid;
			Dispatcher.dispatch({
				actionType: ActionTypes.GET_REVIEWS,
				data: { itineraryId, reviewedId }
			});
		} else {
			reviewerId = user.uid;
			Dispatcher.dispatch({
				actionType: ActionTypes.GET_REVIEW,
				data: { itineraryId, reviewerId }
			});
		}

		this.state = {
			text: '',
			rating: 0,
			reviewed: {
				photo: defaultPhoto
			},
			reviewer: {
				photo: defaultPhoto
			},
			itineraryId,
			// reviewerId: user.uid
			reviewerId,
			reviewedId,
			isAmbassador,
			requestLoading: false
		};
	}

	componentDidMount() {
		UserStore.addListener(EventTypes.REVIEW_LOADED, this.onReviewLoaded);
		UserStore.addListener(EventTypes.REVIEWS_LOADED, this.onReviewsLoaded);
		UserStore.addListener(EventTypes.REVIEW_UPDATED, this.onReviewUpdated);
	}

	componentWillUnmount() {
		UserStore.removeListener(EventTypes.REVIEW_LOADED, this.onReviewLoaded);
		UserStore.removeListener(
			EventTypes.REVIEWS_LOADED,
			this.onReviewsLoaded
		);
		UserStore.removeListener(
			EventTypes.REVIEW_UPDATED,
			this.onReviewUpdated
		);
	}

	onReviewLoaded = () => {
		const review = UserStore.getReview();
		if (!review) return;
		this.setState({
			...review
		});
	};

	onReviewsLoaded = () => {
		const reviews = UserStore.getReviews();
		if (!reviews) return;
		this.setState({
			reviews
		});
	};

	onReviewUpdated() {}

	onStarClick(event) {
		const rating = event.target.id;

		this.setState({
			rating
		});
	}

	onTextInput = event => {
		const text = event.target.value;
		this.setState({
			text
		});
	};

	onReviewSave = () => {
		this.setState({
			requestLoading: true
		});
		const review = this.state;
		Dispatcher.dispatch({
			actionType: ActionTypes.UPDATE_REVIEW,
			data: review
		});
		this.props.history.push('/trips/' + this.state.itineraryId);
	};

	render() {
		const {
			isAmbassador,
			reviewed,
			text,
			rating,
			reviews,
			requestLoading
		} = this.state;

		if (isAmbassador) {
			if (!reviews) return null;
			return (
				<div>
					{reviews.map((review, index) => {
						return (
							<div
								key={index}
								style={{
									backgroundColor: '#f4f4f4',
									marginTop: '25%',
									padding: 20,
									marginBottom: 30,
									borderRadius: 5
								}}
							>
								<img
									alt=""
									style={{
										maxWidth: 100,
										maxHeight: 100,
										borderRadius: '100%',
										marginBottom: 20
									}}
									src={review.reviewer.photo}
								></img>
								<div>
									<span>{review.reviewer.firstName}</span>
									<p>{review.text}</p>
									<div
										style={{
											display: 'flex',
											justifyContent: 'center'
										}}
									>
										{[1, 2, 3, 4, 5].map((idx, index) => {
											return (
												<FontAwesomeIcon
													key={index}
													icon={
														idx <= review.rating
															? faStar
															: regularStart
													}
													size="3x"
													style={{
														marginRight: 5,

														color: '#ffd34f'
													}}
												></FontAwesomeIcon>
											);
										})}
									</div>
								</div>
							</div>
						);
					})}
				</div>
			);
		} else {
			return (
				<div>
					<div
						style={{
							backgroundColor: '#f4f4f4',
							marginTop: '25%',
							padding: 20,
							marginBottom: 30,
							borderRadius: 5
						}}
					>
						<img
							alt=""
							style={{
								maxWidth: 180,
								maxHeight: 180,
								borderRadius: '100%',
								marginTop: '-30%',
								marginBottom: 20
							}}
							src={reviewed.photo}
						></img>
						<div>
							<span>
								How was your experience with
								{reviewed.firstName}?
							</span>
							<div style={{ fontSize: 18, fontWeight: 800 }}>
								Leave a Review
							</div>
							<textarea
								id="review-input"
								placeholder="Leave a review"
								style={{
									fontWeight: 400,
									color: '#838383',
									marginTop: 20,
									width: '100%'
								}}
								value={text}
								onChange={e => this.onTextInput(e)}
							></textarea>
							<div>Rate the Experience</div>
							<div
								style={{
									display: 'flex',
									justifyContent: 'center'
								}}
							>
								{[1, 2, 3, 4, 5].map((idx, index) => {
									return (
										<FontAwesomeIcon
											onClick={e => this.onStarClick(e)}
											key={index}
											icon={
												idx <= rating
													? faStar
													: regularStart
											}
											size="3x"
											id={idx}
											style={{
												marginRight: 5,
												color: '#ffd34f'
											}}
										></FontAwesomeIcon>
									);
								})}
							</div>
						</div>
					</div>

					<div onClick={this.onReviewSave}>
						<BtnLoader loading={requestLoading} text={'Save'} />
					</div>
				</div>
			);
		}
	}
}

export default Review;
