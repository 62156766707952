import React, { Component } from 'react';
import { Button, Input } from 'reactstrap';
import { isEmpty } from 'lodash';
import moment from 'moment';

import config from '../../config';
import { firebaseApp } from '../../firebase';
import Mailer from '../../common/mailer';

import Dispatcher from '../../common/dispatcher/index';
import UserStore from '../../common/stores/user';
import ActionTypes from '../../common/actions';
import EventTypes from '../../common/events';

import { Loading } from '../utils/Loading';
import defaultChatPic from '../../assets/chat-default.jpg';
import { CHAT_DISPLAY_FORMAT } from '../../consts/DateFormats';

import './chat.scss';

class Chat extends Component {
	constructor(props) {
		super();
		const user = UserStore.getUser();
		const chatId = props.match.params.id;
		const chat = UserStore.getChat(chatId);
		const isAdmin = UserStore.isAdmin();
		// TODO: handle group chat

		this.state = {
			userId: user.uid,
			chatId,
			chat,
			currentMessage: '',
			flag: true,
			visible: true,
			isAdmin
		};

		if (isEmpty(chat)) {
			Dispatcher.dispatch({
				actionType: ActionTypes.GET_CHAT,
				data: chatId
			});
		}
	}

	componentDidMount() {
		UserStore.addListener(EventTypes.CHAT_LOADED, this.onChatLoaded);
		firebaseApp
			.firestore()
			.collection(`chats`)
			.doc(this.state.chatId)
			.onSnapshot(
				doc => {
					const existingChat = this.state.chat;
					const incomingChat = doc.data();
					const messages = incomingChat.messages;
					const chat = existingChat
						? {
								...existingChat,
								messages
						  }
						: {
								...incomingChat
						  };
					this.setState({
						chat
					});
					window.scrollTo(0, document.body.scrollHeight);
				},
				function(error) {
					console.log(error);
				}
			);
		window.scrollTo(0, document.body.scrollHeight);
	}

	componentWillUnmount() {
		UserStore.removeListener(EventTypes.CHAT_LOADED, this.onChatLoaded);
	}

	onChatLoaded = () => {
		const { chatId, isAdmin } = this.state;
		const chat = UserStore.getChat(chatId);
		this.setState({
			chat
		});
		let { participants } = chat;
		const { userId } = this.state;
		if (isAdmin) {
			let adminData = {
				uid: userId,
				firstName: 'Redknot Team',
				photo: ''
			};

			participants = { ...participants, adminData };
			this.setState({ participants });
		}
		let newArray = Object.values(participants);
		if (!newArray.some(e => e.uid === userId)) {
			this.setState({
				visible: false
			});
		}
	};

	onWriteMessage = event => {
		const currentMessage = event.target.value;
		this.setState({
			currentMessage
		});
	};

	onKeyPress = event => {
		if (event.key === 'Enter') {
			this.onSendMessage();
		}
	};

	onSendMessage = () => {
		const actualDate = new Date();
		const { userId, currentMessage, chat, chatId, flag } = this.state;
		const { messages, participants } = chat;
		let userName = '';
		let email = '';
		Object.values(participants).forEach(participant => {
			if (participant.uid === userId) {
				userName = participant.firstName;
			} else {
				email = participant.email;
			}
		});

		const message = {
			senderId: userId,
			text: currentMessage,
			timestamp: moment(actualDate).format(CHAT_DISPLAY_FORMAT)
		};

		Dispatcher.dispatch({
			actionType: ActionTypes.CREATE_MESSAGE,
			data: { message, chatId }
		});

		messages.push(message);

		this.setState({
			currentMessage: '',
			chat: {
				...chat,
				messages
			}
		});

		// only send when in production
		if (config.isProd) {
			Mailer.sendChatNotification(
				email,
				userName,
				currentMessage,
				flag,
				chatId
			);
		}
		this.setState({
			flag: !this.state.flag
		});
	};

	render() {
		const { chat, userId, currentMessage, visible } = this.state;

		if (!chat) {
			return <Loading></Loading>;
		}

		window.scrollTo(0, document.body.scrollHeight);

		const { messages, participants } = chat;
		let isResponse, participant;

		return (
			<div>
				<h3>Chat</h3>
				<div id="chat-container">
					<div>
						<div style={{ marginBottom: 70 }}>
							{messages.map(message => {
								participant = participants[message.senderId];
								let chatImg = participant
									? participant.photo
									: defaultChatPic;

								isResponse = message.senderId !== userId;

								return (
									<div
										className={
											isResponse
												? 'message received'
												: 'message sent'
										}
										style={{
											display: 'flex',
											flexDirection: isResponse
												? 'row-reverse'
												: 'row'
										}}
									>
										<div className="message-text">
											<div style={{}}>
												<p>{message.text}</p>
											</div>
											<span
												style={{
													fontSize: '10px',
													float: 'right'
												}}
											>
												{message.timestamp}
											</span>
										</div>
										<div
											style={{
												width: 32,
												height: 32,
												borderRadius: '100%',
												backgroundImage:
													'url(' + chatImg + ')',
												margin: '0 5px',
												backgroundSize: 'cover',
												backgroundPosition: 'top'
											}}
										></div>
									</div>
								);
							})}
						</div>
					</div>
					{visible && (
						<div className="chat-container">
							<div className="chat-input-container">
								<Input
									placeholder="write a message"
									value={currentMessage}
									onChange={this.onWriteMessage}
									onKeyPress={this.onKeyPress}
								></Input>
							</div>
							<div>
								<Button onClick={this.onSendMessage}>
									Send
								</Button>
							</div>
						</div>
					)}
				</div>
			</div>
		);
	}
}

export default Chat;
