import Store from './store';
import Dispatcher from '../dispatcher/index';
import ActionTypes from '../actions';
import EventTypes from '../events';
import Mailer from '../../common/mailer';
import config from '../../config';

let store = {
	user: null,
	userAuthed: false,
	profile: null, // TODO: this goes in another store
	searchResult: [], // TODO: this goes in another store
	booking: {},
	itinerary: {},
	itineraries: [],
	chats: {},
	review: {},
	reviews: [],
	interests: {}
};

const url = config.backendUrl;
let payload,
	token = {};

class UserStore extends Store {
	getUser() {
		return store.user;
	}

	setUser(data) {
		store.user = data;
		store.userAuthed = true;
	}

	isAuthed() {
		return store.userAuthed;
	}

	isAmbassador() {
		return store.user ? store.user.isAmbassador : false;
	}

	isAgent() {
		return store.user ? store.user.isAgent : false;
	}

	isAdmin() {
		return store.user ? store.user.isAdmin : false;
	}

	getRole() {
		const user = store.user;
		if (!user) return;
		const isAmbassador = user.isAmbassador;
		const isAgent = user.isAgent;

		if (isAmbassador) {
			return 'ambassador';
		} else if (isAgent) {
			return 'agent';
		} else {
			return 'traveler';
		}
	}

	logout() {
		store.user = null;
		store.userAuthed = false;
	}

	getUserProfile() {
		return store.profile;
	}

	getBooking() {
		return store.booking;
	}

	getItinerary() {
		return store.itinerary;
	}

	getItineraries() {
		return store.itineraries;
	}

	getChat(id) {
		return store.chats[id];
	}

	getReview() {
		return store.review;
	}

	getReviews() {
		return store.reviews;
	}

	getInterests() {
		return store.interests;
	}
}

let userStoreInstance = new UserStore();

let id, uid;
const stripe = window.Stripe(config.stripeKey);

userStoreInstance.dispatchToken = Dispatcher.register(action => {
	switch (action.actionType) {
		case ActionTypes.CREATE_USER:
			payload = action.data;
			try {
				fetch(`${url}/createUser`, {
					method: 'POST',
					mode: 'no-cors',
					headers: {
						'Content-Type': 'application/json'
					},
					body: JSON.stringify(payload)
				}).then(() => {
					// this.userStore.setUser(payload);
					store.user = {
						email: payload.email,
						isAmbassador: payload.isAmbassador,
						firstName: '',
						carType: '',
						bio: ''
					};

					// send mail to traveler
					Mailer.sendUserSignupMessage(payload.email);

					userStoreInstance.emit(EventTypes.USER_CREATED);
				});
			} catch (error) {
				console.error(error);
			}

			break;

		case ActionTypes.UPDATE_USER:
			payload = action.data;

			try {
				fetch(`${url}/updateAmbassador`, {
					method: 'POST',
					mode: 'no-cors',
					headers: {
						'Content-Type': 'application/json'
					},
					body: JSON.stringify(payload)
				}).then(() => {
					store.user = payload;
					userStoreInstance.emit(EventTypes.USER_UPDATED);
				});
			} catch (error) {
				console.error(error);
			}
			break;

		case ActionTypes.USER_AUTHENTICATED:
			// console.log('user_authed action');
			// TODO: abstract to api call class
			if (action.data.user) {
				const uid = action.data.user.uid;
				const email = action.data.user.email;
				if (action.data.idToken) {
					token = action.data.idToken.token;
				} else {
					token = '';
				}
				try {
					fetch(`${url}/user?uid=${uid}&token=${token}`, {
						method: 'GET',
						headers: {
							'Content-Type': 'application/json'
						}
					}).then(async resp => {
						const user = await resp.json();
						user['uid'] = uid;
						user['email'] = email;
						store.user = user;
						store.userAuthed = true;
						userStoreInstance.emit(EventTypes.USER_LOADED);
					});
				} catch (error) {
					console.error(error);
				}
			} else {
				// console.log('null user');
				store.user = null;
				store.userAuthed = false;
				userStoreInstance.emit(EventTypes.USER_LOADED);
			}
			break;

		case ActionTypes.GET_USER_PROFILE:
			uid = action.data;
			try {
				fetch(`${url}/profile?uid=${uid}`, {
					method: 'GET',
					headers: {
						'Content-Type': 'application/json'
					}
				}).then(async resp => {
					const profile = await resp.json();
					store.profile = profile;
					userStoreInstance.emit(EventTypes.PROFILE_LOADED);
				});
			} catch (error) {
				console.error(error);
			}
			break;

		case ActionTypes.GET_BOOKING:
			id = action.data;
			// console.log('get booking');
			try {
				fetch(`${url}/booking/?id=${id}`, {
					method: 'GET',
					headers: {
						'Content-Type': 'application/json'
					}
				}).then(async resp => {
					const booking = await resp.json();
					store.booking = booking;
					userStoreInstance.emit(EventTypes.BOOKING_LOADED);
				});
			} catch (error) {
				console.error(error);
			}
			break;

		case ActionTypes.GET_ITINERARY:
			id = action.data;
			// console.log('get itinerary');
			try {
				fetch(`${url}/itinerary/?id=${id}`, {
					method: 'GET',
					headers: {
						'Content-Type': 'application/json'
					}
				}).then(async resp => {
					const itinerary = await resp.json();
					store.itinerary = itinerary;
					userStoreInstance.emit(EventTypes.ITINERARY_LOADED);
				});
			} catch (error) {
				console.error(error);
			}
			break;

		case ActionTypes.CREATE_BOOKING:
			// console.log('create booking action');
			const user = store.user || [];
			payload = action.data;
			if (user.isAgent) {
				let agentId = user ? user.uid : null;
				payload['agentId'] = agentId;
				payload['travelerId'] = null;
			} else {
				let travelerId = user ? user.uid : null;
				payload['agentId'] = null;
				payload['travelerId'] = travelerId;
			}
			try {
				fetch(`${url}/createBooking`, {
					method: 'POST',
					mode: 'cors',
					headers: {
						'Content-Type': 'application/json'
					},
					body: JSON.stringify(payload)
				}).then(async res => {
					const booking = await res.json();
					// TODO: fix; understand when to get ambassador profile for the booking view
					store.booking = booking;
					userStoreInstance.emit(EventTypes.BOOKING_CREATED);
				});
			} catch (error) {
				console.error(error);
			}

			break;

		case ActionTypes.CREATE_ITINERARY:
			// console.log('create itinerary action');
			// const travelerId = store.user.uid;
			payload = action.data;
			try {
				fetch(`${url}/createItinerary`, {
					method: 'POST',
					mode: 'cors',
					headers: {
						'Content-Type': 'application/json'
					},
					body: JSON.stringify(payload)
				}).then(async res => {
					const itinerary = await res.json();
					store.itinerary = {
						ambassadorId: payload.ambassadorId,
						travelerId: payload.travelerId,
						agentId: payload.agentId,
						id: itinerary.id,
						travelers: [payload.travelerId],
						bookingId: payload.bookingId,
						city: payload.city,
						days: payload.days,
						chatId: itinerary.chatId
					};
					store.chats[itinerary.chatId] = {
						messages: {},
						participants: {
							[payload.ambassadorId]: {},
							[payload.travelerId]: {},
							[payload.agentId]: {}
						}
					};
					userStoreInstance.emit(EventTypes.ITINERARY_CREATED);
				});
			} catch (error) {
				console.error(error);
			}

			break;

		case ActionTypes.SAVE_ITINERARY:
			// console.log('save itinerary action');
			payload = action.data;
			try {
				fetch(`${url}/saveItinerary`, {
					method: 'POST',
					mode: 'no-cors',
					headers: {
						'Content-Type': 'application/json'
					},
					body: JSON.stringify(payload)
				}).then(() => {
					store.itinerary = {
						ambassadorId: payload.ambassadorId,
						travelerId: payload.travelerId,
						agentId: payload.agentId,
						travelers: [payload.travelerId],
						bookingId: payload.bookingId,
						city: payload.city,
						days: payload.days,
						id: payload.id
					};
					userStoreInstance.emit(EventTypes.ITINERARY_SAVED);
				});
			} catch (error) {
				console.error(error);
			}

			break;

		case ActionTypes.CREATE_CHECKOUT_SESSION:
			// console.log('create checkout session action');
			payload = action.data;
			try {
				fetch(`${url}/checkoutSession`, {
					method: 'POST',
					mode: 'cors',
					headers: { 'Content-Type': 'application/json' },
					body: JSON.stringify(payload)
				}).then(async resp => {
					if (resp.ok) {
						const session = await resp.json();
						const sessionId = session.id;
						store.sessionId = sessionId;

						stripe
							.redirectToCheckout({ sessionId })
							.then(function(result) {
								if (result.error) {
									alert(result.error.message);
								}
							});
					} else {
						console.log(
							'checkout session not successfully created'
						);
					}
				});
			} catch (error) {
				console.error(error);
			}

			break;

		case ActionTypes.UPDATE_BOOKING:
			// console.log('update booking action');
			payload = action.data;
			try {
				fetch(`${url}/saveBooking`, {
					method: 'POST',
					mode: 'no-cors',
					headers: {
						'Content-Type': 'application/json'
					},
					body: JSON.stringify(payload)
				}).then(() => {
					store.booking = payload.booking;
					userStoreInstance.emit(EventTypes.BOOKING_UPDATED);
				});
			} catch (error) {
				console.error(error);
			}

			break;

		case ActionTypes.UPDATE_AVAILABILITY:
			// console.log('update availability action');
			payload = action.data;
			try {
				fetch(`${url}/updateAvailability`, {
					method: 'POST',
					mode: 'no-cors',
					headers: {
						'Content-Type': 'application/json'
					},
					body: JSON.stringify(payload)
				}).then(() => {
					// store.user.availability = payload.booking;
					userStoreInstance.emit(EventTypes.AVAILABILITY_UPDATED);
				});
			} catch (error) {
				console.error(error);
			}

			break;

		case ActionTypes.GET_ITINERARIES:
			// console.log('get itineraries action');
			uid = action.data.uid;
			const userType = action.data.userType;
			try {
				fetch(`${url}/${userType}Itineraries?uid=${uid}`, {
					method: 'GET',
					headers: {
						'Content-Type': 'application/json'
					}
				}).then(async resp => {
					const itineraries = await resp.json();
					store.itineraries = itineraries;
					userStoreInstance.emit(EventTypes.ITINERARIES_LOADED);
				});
			} catch (error) {
				console.error(error);
			}

			break;

		case ActionTypes.GET_CHAT:
			// console.log('get chat action');
			id = action.data;
			try {
				fetch(`${url}/chat?id=${id}`, {
					method: 'GET',
					headers: {
						'Content-Type': 'application/json'
					}
				}).then(async resp => {
					const { id, messages, participants } = await resp.json();
					store.chats[id] = {
						id,
						messages,
						participants
					};
					userStoreInstance.emit(EventTypes.CHAT_LOADED);
				});
			} catch (error) {
				console.error(error);
			}

			break;

		case ActionTypes.CREATE_MESSAGE:
			// console.log('create message action');
			payload = action.data;
			try {
				fetch(`${url}/createMessage`, {
					method: 'POST',
					mode: 'no-cors',
					headers: {
						'Content-Type': 'application/json'
					},
					body: JSON.stringify(payload)
				}).then(() => {
					userStoreInstance.emit(EventTypes.MESSAGE_CREATED);
				});
			} catch (error) {
				console.error(error);
			}
			break;

		case ActionTypes.GET_REVIEW:
			// console.log('get review action');
			id = action.data.itineraryId;
			uid = action.data.reviewerId;
			try {
				fetch(`${url}/review?id=${id}&uid=${uid}`, {
					method: 'GET',
					headers: {
						'Content-Type': 'application/json'
					}
				}).then(async resp => {
					const review = await resp.json();
					store.review = review;
					userStoreInstance.emit(EventTypes.REVIEW_LOADED);
				});
			} catch (error) {
				console.error(error);
			}
			break;

		case ActionTypes.UPDATE_REVIEW:
			// console.log('update review action');
			payload = action.data;
			// id = action.data.id;
			try {
				fetch(`${url}/updateReview`, {
					method: 'POST',
					mode: 'no-cors',
					headers: {
						'Content-Type': 'application/json'
					},
					body: JSON.stringify(payload)
				}).then(() => {
					store.review = payload.review;
					userStoreInstance.emit(EventTypes.REVIEW_UPDATED);
				});
			} catch (error) {
				console.error(error);
			}
			break;

		case ActionTypes.GET_REVIEWS:
			// console.log('get reviews action');
			id = action.data.itineraryId;
			uid = action.data.reviewedId;
			try {
				fetch(`${url}/reviews?id=${id}&uid=${uid}`, {
					method: 'GET',
					headers: {
						'Content-Type': 'application/json'
					}
				}).then(async resp => {
					const reviews = await resp.json();
					store.reviews = reviews;
					userStoreInstance.emit(EventTypes.REVIEWS_LOADED);
				});
			} catch (error) {
				console.error(error);
			}
			break;

		case ActionTypes.GET_INTERESTS:
			// console.log('get interests action');
			try {
				fetch(`${url}/interests`, {
					method: 'GET',
					headers: {
						'Content-Type': 'application/json'
					}
				}).then(async resp => {
					const interests = await resp.json();
					store.interests = interests;
					userStoreInstance.emit(EventTypes.INTERESTS_LOADED);
				});
			} catch (error) {
				console.error(error);
			}
			break;

		// GRANULAR REDUCERS //

		case ActionTypes.ADD_USER_TO_BOOKING:
			console.log('add user to booking action');
			payload = action.data;
			try {
				fetch(`${url}/addTravelerToBooking`, {
					method: 'POST',
					mode: 'no-cors',
					headers: {
						'Content-Type': 'application/json'
					},
					body: JSON.stringify(payload)
				}).then(() => {
					store.booking = payload.booking;
					userStoreInstance.emit(EventTypes.USER_ADDED_TO_BOOKING);
				});
			} catch (error) {
				console.error(error);
			}
			break;

		default:
			console.error('No known action.');
			break;
	}
});

export default userStoreInstance;
