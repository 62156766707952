import React, { Component } from 'react';
import { isEmpty } from 'lodash';
import { Label } from 'reactstrap';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';

import UserStore from '../../common/stores/user';
import EventTypes from '../../common/events';

import defaultPhoto from '../../assets/default-placeholder.png';
import { DOB_DISPLAY_FORMAT } from '../../consts/DateFormats';

import './profile.scss';

class Profile extends Component {
	constructor() {
		super();
		const user = UserStore.getUser();

		this.state = {
			user
		};
	}

	componentDidMount() {
		window.scrollTo(0, 0);
		UserStore.addListener(EventTypes.USER_LOADED, this.onUserLoaded);
	}

	componentWillUnmount() {
		UserStore.removeListener(EventTypes.USER_LOADED, this.onUserLoaded);
	}

	onUserLoaded = () => {
		const { user } = this.state;

		this.setState({
			user
		});
	};

	render() {
		const { user } = this.state;
		let {
			firstName,
			bio,
			city,
			country,
			lifestylePics,
			isAmbassador,
			gender,
			carType,
			countryOrigin,
			tagline,
			places,
			fav,
			languages,
			dob
		} = user;

		const photo = user.photo || defaultPhoto;
		const exampleDay = user.exampleDay || {};
		const dayTimes = Object.keys(exampleDay);

		return (
			<div id="profile-view">
				<div
					style={{
						width: 150,
						height: 150,
						borderRadius: '100%',
						backgroundImage: 'url(' + photo + ')',
						margin: '0 auto',
						backgroundSize: 'cover',
						backgroundPosition: 'top'
					}}
				></div>
				<div className="profile-desc">
					<span className="city">{city}</span>
					<span className="country">{country}</span>
					<span className="name">{firstName}</span>
					<p className="bio">{bio}</p>

					{!isEmpty(gender) && (
						<div className="user-profile-field">
							<Label>Gender</Label>
							<p className="text-capitalized">{gender}</p>
						</div>
					)}
					{!isEmpty(dob) && (
						<div className="user-profile-field">
							<Label>Date of birth</Label>
							<p>{moment(dob).format(DOB_DISPLAY_FORMAT)}</p>
						</div>
					)}
					{!isEmpty(countryOrigin) && (
						<div className="user-profile-field">
							<Label>Country of origin</Label>
							<p className="text-capitalized">{countryOrigin}</p>
						</div>
					)}
					{!isEmpty(carType) && (
						<div className="user-profile-field">
							<Label>Type of vehicle</Label>
							<p className="text-capitalized">{carType}</p>
						</div>
					)}
					{!isEmpty(tagline) && (
						<div className="user-profile-field">
							<Label>Headilne</Label>
							<p>{tagline}</p>
						</div>
					)}
					{!isEmpty(languages) && (
						<div className="user-profile-field">
							<Label>Languages you speak</Label>
							<p>{languages}</p>
						</div>
					)}
					{!isEmpty(places) && (
						<div className="user-profile-field">
							<Label>Places you've been</Label>
							<p>{places}</p>
						</div>
					)}
					{!isEmpty(fav) && (
						<div className="user-profile-field">
							<Label>
								Your favorite things to do in your country
							</Label>
							<p>{fav}</p>
						</div>
					)}
					{!isEmpty(exampleDay) && (
						<div className="user-profile-field">
							<Label>What a day with you would look like</Label>
							{dayTimes.map(time => {
								return (
									<div>
										<span>{time}</span>
										<p>{exampleDay[time]}</p>
									</div>
								);
							})}
						</div>
					)}
				</div>
				{!isEmpty(lifestylePics) && (
					<div id="lifestyle-photos">
						<Label>Photos of your lifestyle</Label>
						<div className="lifestyle-grid">
							{lifestylePics.map((pic, idx) => {
								return (
									<div
										className="lifestyle-pic"
										key={idx}
										style={{
											backgroundImage:
												"url('" + pic.url + " ')"
										}}
									></div>
								);
							})}
						</div>
					</div>
				)}

				<div className="mt-3">
					{isAmbassador ? (
						<button
							className="button-primary icon-button"
							onClick={() => {
								this.props.history.push('/editProfile');
							}}
						>
							<FontAwesomeIcon
								icon={faPen}
								style={{ marginRight: '5px' }}
							/>
							Edit Profile
						</button>
					) : (
						<div>
							<button
								className="button-primary icon-button"
								onClick={() => {
									this.props.history.push('/editProfile');
								}}
								style={{ margin: '15px 0' }}
							>
								<FontAwesomeIcon
									icon={faPen}
									style={{ marginRight: '5px' }}
								/>
								Edit Profile
							</button>
							{firstName && (
								<>
									<button
										className="button-secondary"
										onClick={() => {
											this.props.history.push('/new');
										}}
									>
										New Trip
									</button>
									<button
										className="button-secondary"
										onClick={() => {
											this.props.history.push('/trips/');
										}}
										style={{ margin: '15px 0' }}
									>
										My Trips
									</button>
								</>
							)}
						</div>
					)}
				</div>
			</div>
		);
	}
}

export default Profile;
