import React, { Component } from 'react';
import firebase from 'firebase';
import { Input, InputGroupAddon, InputGroup } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faLock } from '@fortawesome/free-solid-svg-icons';

import UserStore from '../../common/stores/user';
import ActionTypes from '../../common/actions';
import EventTypes from '../../common/events';
import Dispatcher from '../../common/dispatcher/index';

import { Loading } from '../utils/Loading';
import Alert from '../utils/Alert';
import BtnLoader from '../utils/BtnLoader';

import google from '../../assets/google-01-min.png';
import facebook from '../../assets/facebook.png';

import './auth.scss';

class Login extends Component {
	constructor(props) {
		super();
		// when logging in before booking, the url comes with the bookingId
		const bookingId = props.match.params.id;
		this.state = {
			email: '',
			password: '',
			isAuthed: UserStore.isAuthed(),
			isLoading: false,
			bookingId,
			requestLoading: false,
			isOpen: false,
			errorData: {}
		};
	}

	componentDidMount() {
		UserStore.addListener(EventTypes.USER_LOADED, this.onUserLoaded);
	}

	componentWillUnmount() {
		UserStore.removeListener(EventTypes.USER_LOADED, this.onUserLoaded);
	}

	onUserLoaded = () => {
		const isAuthed = UserStore.isAuthed();
		this.setState({ isAuthed });
	};

	handleChange = event => {
		const key = event.target.id;
		const value = event.target.value;
		this.setState({ [key]: value });
	};

	showLoading = () => {
		this.setState({
			isLoading: true
		});
	};

	dismiss = () => {
		this.setState({ isOpen: false });
	};

	emailAuth = event => {
		event.preventDefault();
		const { email, password } = this.state;

		if (email.length < 4) {
			alert('Please enter an email address.');
			return;
		}

		if (password.length < 4) {
			alert('Please enter a password.');
			return;
		}
		this.setState({ requestLoading: true });
		// TODO: encapsulate firebase actions
		firebase
			.auth()
			.signInWithEmailAndPassword(email, password)
			.then(credential => {
				this.showLoading();
				Dispatcher.dispatch({
					actionType: ActionTypes.USER_AUTHENTICATED,
					data: credential
				});
			})
			.catch(error => {
				if (error.code === 'auth/user-not-found') {
					error.code = 'User not Found';
					error.message =
						'There is no account associated with this email address. Please try again or register';
				}
				this.setState({
					isOpen: true,
					errorData: error
				});
			})
			.finally(() => {
				this.setState({ requestLoading: false });
			});
	};

	googleAuth = () => {
		const provider = new firebase.auth.GoogleAuthProvider();
		firebase
			.auth()
			.signInWithPopup(provider)
			.then(result => {
				this.showLoading();
				const user = result.user;
				const firstName = result.user.displayName;
				if (result.additionalUserInfo.isNewUser) {
					Dispatcher.dispatch({
						actionType: ActionTypes.CREATE_USER,
						data: {
							email: user.email,
							uid: user.uid,
							firstName,
							isAmbassador: false
						}
					});
				}
			})
			.catch(this.props.onError);
	};

	fbAuth = () => {
		const provider = new firebase.auth.FacebookAuthProvider();
		firebase
			.auth()
			.signInWithPopup(provider)
			.then(result => {
				this.showLoading();
				const user = result.user;
				const firstName = user.displayName;
				if (result.additionalUserInfo.isNewUser) {
					Dispatcher.dispatch({
						actionType: ActionTypes.CREATE_USER,
						data: {
							email: user.email,
							uid: user.uid,
							firstName,
							isAmbassador: false
						}
					});
				}
			})
			.catch(this.props.onError);
	};

	resetPassword = () => {
		const email = this.state.email;

		if (email.length < 4) {
			alert('Please enter an email address.');
			return;
		}

		firebase
			.auth()
			.sendPasswordResetEmail(email)
			.then(function() {
				alert('A password recovery link was sent to ' + email + '!');
			})
			.catch(function(error) {
				console.warn(error);
				alert('error sending email');
			});
	};

	render() {
		const { isLoading, requestLoading, isOpen, errorData } = this.state;

		if (isLoading) {
			return <Loading></Loading>;
		}

		return (
			<div className="auth-container">
				<Alert
					content={errorData.message}
					dismiss={() => this.dismiss}
					isOpen={isOpen}
				/>
				<h2 className="title">Log In</h2>
				<div className="auth-form">
					<button className="button-auth" onClick={this.googleAuth}>
						<img alt={'google-icon'} src={google}></img>
						Log In with Google
					</button>
					<button className="button-auth" onClick={this.fbAuth}>
						<img alt={'facebook-icon'} src={facebook}></img>
						Log In with Facebook
					</button>
					<div className="auth-divider">
						<span className="auth-divider-line">
							<hr></hr>
						</span>
						<span className="auth-divider-o">O</span>
						<span className="auth-divider-line">
							<hr></hr>
						</span>
					</div>
					<form className="auth-fields" onSubmit={this.emailAuth}>
						<InputGroup>
							<InputGroupAddon addonType="prepend">
								<FontAwesomeIcon icon={faEnvelope} />
							</InputGroupAddon>
							<Input
								id="email"
								className="form-input"
								placeholder="Email"
								onChange={this.handleChange}
							></Input>
						</InputGroup>
						<InputGroup style={{ marginTop: 15, marginBottom: 30 }}>
							<InputGroupAddon addonType="prepend">
								<FontAwesomeIcon icon={faLock} />
							</InputGroupAddon>
							<Input
								id="password"
								className="form-input"
								type="password"
								placeholder="Password"
								onChange={this.handleChange}
							></Input>
						</InputGroup>
						<BtnLoader loading={requestLoading} text={'Log In'} />
					</form>
				</div>
				<div className="font-regular">
					<div
						className="link-primary"
						onClick={() => {
							// pass the bookingId to signup to be able to redirect the user afterwards
							const bookingId = this.state.bookingId;
							this.props.history.push({
								pathname: '/signup',
								state: {
									bookingId
								}
							});
						}}
					>
						<button className="button-secondary">
							Register{' '}
							<span role="img" aria-label="">
								👈
							</span>
						</button>
					</div>
					<div className="font-small mt-4">
						<span>
							Help!{' '}
							<strong>
								<u onClick={this.resetPassword}>
									I forgot my password
								</u>
							</strong>
							<span role="img" aria-label="">
								😅
							</span>
						</span>
					</div>
				</div>
			</div>
		);
	}
}

export default Login;
