import React, { Component } from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import AnalyticsListener from './Analytics';
import ReactPixel from './common/FbPixel';
import { ApolloClient } from 'apollo-boost';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import { ApolloProvider } from 'react-apollo';
import { StripeProvider } from 'react-stripe-elements';

import config from './config';
import routes from './routes';
import UserStore from './common/stores/user';
import EventTypes from './common/events';

import './app.scss';
import './overrides.scss';
import './components/elements.scss';
import './components/buttons.scss';
import './components/inputs.scss';

const cache = new InMemoryCache();
const link = new HttpLink({
	uri: config.graphqlUrl
});

const client = new ApolloClient({
	cache,
	link
});

class App extends Component {
	constructor() {
		super();
		this.state = {
			isAuthed: UserStore.isAuthed(),
			isAmbassador: UserStore.isAmbassador(),
			isUserLoaded: true
		};
	}

	componentDidMount = () => {
		UserStore.addListener(EventTypes.USER_LOADED, this.OnUserLoaded);
		this.setState({
			isUserLoaded: false
		});
		ReactPixel.init(config.fbPixel, {}, { debug: true, autoConfig: false });
	};

	OnUserLoaded = () => {
		this.setState({
			isAuthed: UserStore.isAuthed(),
			isAmbassador: UserStore.isAmbassador(),
			isUserLoaded: true
		});
	};

	render() {
		const { isAuthed, isUserLoaded, isAmbassador } = this.state;

		return (
			<StripeProvider apiKey={config.stripeKey}>
				<ApolloProvider client={client}>
					<Router>
						<AnalyticsListener trackingId="UA-146230051-1">
							<div className="App">
								<Switch>
									{routes.map(route => {
										return (
											<route.type
												key={route.path}
												path={route.path}
												exact={route.exact}
												isAuthed={isAuthed}
												isAmbassador={isAmbassador}
												isUserLoaded={isUserLoaded}
												component={route.component}
												title={route.title}
											/>
										);
									})}
								</Switch>
							</div>
						</AnalyticsListener>
					</Router>
				</ApolloProvider>
			</StripeProvider>
		);
	}
}

export default App;
