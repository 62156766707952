import React, { Component } from 'react';
import firebase from 'firebase';
import moment from 'moment';
import { isEmpty, findKey, isNil } from 'lodash';
import { Button, Input, Label } from 'reactstrap';

import ReactPixel from '../../common/FbPixel';
import Dispatcher from '../../common/dispatcher/index';
import EventTypes from '../../common/events';
import ActionTypes from '../../common/actions';
import UserStore from '../../common/stores/user';

import BtnLoader from '../utils/BtnLoader';
import Alert from '../utils/Alert';
import { Loading } from '../utils/Loading';
import { GroupSizeHandler } from '../blocks/GroupSizeHandler';

import { getUserProfileName } from '../../utils/Validation';

import {
	BOOKING_DB_FORMAT,
	BOOKING_DISPLAY_FORMAT
} from '../../consts/DateFormats';

import './booking.scss';

// TODO: get from the DB
const PROMO_CODES = {
	xsuo3ma8tHlJ51j9c41Y: {
		code: 'REDKNOT30',
		ammountOff: 30
	},
	HP08Icav0QLhU0P9EJmc: {
		code: 'HOLIDAY35',
		ammountOff: 35
	},
	uDSqcOZgO7IZ4kmIQ7Fg: {
		code: 'RedKnotFree',
		ammountOff: 120
	},
	hcRapQvXg2lJI94wvqAG: {
		code: 'SVPUROSURF10',
		ammountOff: 10
	},
	zG0Nt2pNxdRzqBPp97DC: {
		code: 'SVLKNGGOOD10',
		ammountOff: 10
	},
	MakqP9gXIh0CWZ7VDFGc: {
		code: 'REDKNOT20',
		ammountOff: 20
	},
	Itg1QqCC6SG9AFAUbDMx: {
		code: 'IFIGUEROA10',
		ammountOff: 10
	},
	TQMDDT5dkmUciQvArr2P: {
		code: 'ABOTRAN10',
		ammountOff: 10
	},
	hfS7OSsh3aS5U9oOow0D: {
		code: 'REDKNOT40',
		ammountOff: 40
	},

	nfaqfo5yqYVfHGXSayf7: {
		code: 'JCOSG10',
		ammountOff: 10
	},
	ebORyXdz4DHSglHAqTUf: {
		code: 'SLVHCTY056',
		ammountOff: 10
	},
	MqGbWuHvrrj96kPzpXzM: {
		code: 'SLVHCTY058',
		ammountOff: 10
	},
	nPe53h7vC2fa273th4FJ: {
		code: 'SLVHCTY057',
		ammountOff: 10
	},

	vcbgeorfakdJSTrqnRiG: {
		code: 'REDKNOT10',
		ammountOff: 10
	},
	T9VU0ScBO90ANmuOUU5M: {
		code: 'MAMAPAN10',
		ammountOff: 10
	},
	aIkXvq9oznnKmuhHoqr2: {
		code: 'CASA10',
		ammountOff: 10
	},
	TqsjfmTdZI3WGDCJvHuz: {
		code: 'GTHCTY077',
		ammountOff: 10
	},
	u9hNqyZQncMakuzDo5vw: {
		code: 'SLVHCTY059',
		ammountOff: 10
	},
	r5bSVKG4uD7Mixwmsr2z: {
		code: 'NOMADE5',
		ammountOff: 10
	},
	iXh8GbLwPCHhYkT0n7HP: {
		code: 'NOMADE10',
		ammountOff: 10
	},
	dFay2SJ9bbEevTDusPon: {
		code: 'GTHCTY077',
		ammountOff: 10
	},
	qTJruCrjLcboqFRMXcmO: {
		code: 'JANGULO10',
		ammountOff: 10
	},
	HiqbVSFO7lqb6gEhGOfG: {
		code: 'CRWITHREDKNOT35',
		ammountOff: 35
	},
	ROh2BugARvGSNStLcSTo: {
		code: 'CRTRIP30',
		ammountOff: 30
	},
	'2CLoohmVltnqL1TBrnSc': {
		code: 'FRFLD10',
		ammountOff: 10
	},
	q9bON3Hfzj1gStA2kEnf: {
		code: 'CRTYRD10',
		ammountOff: 10
	},
	RRHkilJQ3BH2r354D3M6: {
		code: 'HLDY10',
		ammountOff: 10
	},
	'6FeN4iCsbucfG7zx3cE7': {
		code: 'CRWN10',
		ammountOff: 10
	},
	IXbu7gJ75rDHm1Bt8Mq3: {
		code: 'SHRTN10',
		ammountOff: 10
	},
	c2hwJp2wZektRbUFSn8V: {
		code: 'RINT10',
		ammountOff: 10
	},
	J2QscWWySE5mZtEwTw1t: {
		code: 'SALYLUZ10',
		ammountOff: 10
	},
	uRQLBJPVaXWtE2W1YJY2: {
		code: 'MGNLS10',
		ammountOff: 10
	},
	sUuUmFIhEJ4WYK2LZQuq: {
		code: 'CINCO10',
		ammountOff: 10
	},
	H236OCu15pwv88dQYVjA: {
		code: 'BWEST10',
		ammountOff: 10
	},
	qFZ25CI95tBzkLliVCa3: {
		code: 'MIRPLZ10',
		ammountOff: 10
	},
	xa4i6B37aEBNgEssPfVp: {
		code: 'BHILLS10',
		ammountOff: 10
	},
	Ic2IV1lD56xOgwZccCH6: {
		code: 'KNAWI10',
		ammountOff: 10
	}
};

class Booking extends Component {
	constructor(props) {
		super();
		const id = props.match.params.id;
		const booking = UserStore.getBooking();
		const user = UserStore.getUser();

		this.state = {
			bookingId: id,
			booking,
			ambassador: booking.ambassador,
			code: '',
			isAgent: user.isAgent,
			isOpen: false,
			errorData: {},
			validBooking: true
		};

		// TODO: maybe do not request when a booking is set in the store?
		Dispatcher.dispatch({
			actionType: ActionTypes.GET_BOOKING,
			data: id
		});
	}

	componentDidMount() {
		UserStore.addListener(EventTypes.BOOKING_LOADED, this.onBookingLoaded);
		UserStore.addListener(
			EventTypes.USER_ADDED_TO_BOOKING,
			this.createCheckoutSession
		);
		UserStore.addListener(
			EventTypes.USER_CREATED_TO_BOOKING,
			this.createCheckoutSession
		);
		ReactPixel.pageView();
		ReactPixel.fbq('track', 'Booking');
	}

	componentWillUnmount() {
		UserStore.removeListener(
			EventTypes.BOOKING_LOADED,
			this.onBookingLoaded
		);
		UserStore.removeListener(
			EventTypes.USER_ADDED_TO_BOOKING,
			this.createCheckoutSession
		);
		UserStore.addListener(
			EventTypes.USER_CREATED_TO_BOOKING,
			this.createCheckoutSession
		);
	}

	onBookingLoaded = () => {
		const booking = UserStore.getBooking();
		// TODO: find a better way to only save certain fields of the booking
		// (the API returns the whole ambassador and we don't want to save it to the DB)
		const {
			ambassadorId,
			travelerId,
			itineraryId,
			dates,
			price,
			status,
			city,
			couponId,
			ambassador,
			numAdults,
			numChildren,
			conversion
		} = booking;

		this.setState({
			booking: {
				ambassadorId,
				travelerId,
				itineraryId,
				dates,
				price,
				status,
				city,
				couponId,
				numAdults: numAdults || 1,
				numChildren: numChildren || 0,
				conversion
			},
			ambassador: ambassador,
			requestLoading: false
		});
	};

	handleChange = event => {
		const key = event.target.id;
		const value = event.target.value;
		this.setState({ [key]: value });
	};

	handleTravelers = ({ numAdults, numChildren, price, conversion }) => {
		const { booking } = this.state;
		this.setState({
			booking: {
				...booking,
				numAdults,
				numChildren,
				price,
				conversion
			}
		});
	};
	redeemCode = () => {
		const { code, booking, bookingId } = this.state;
		// check wether code exists
		const couponId = findKey(PROMO_CODES, { code: code });
		if (!couponId) {
			this.setState({
				isOpen: true,
				errorData: {
					message: 'Not a valid promo code.'
				}
			});
			return;
		}

		if (booking.couponId) {
			this.setState({
				isOpen: true,
				errorData: {
					message: 'Only one promo code can be used on this booking.'
				}
			});
			return;
		}

		const coupon = PROMO_CODES[couponId];
		const ammountOff = coupon.ammountOff;
		const newPrice = booking.price - ammountOff;
		// const roundPrice = newPrice.toFixed(2);

		this.setState({
			booking: {
				...booking,
				price: newPrice,
				couponId
			}
		});

		Dispatcher.dispatch({
			actionType: ActionTypes.UPDATE_BOOKING,
			data: {
				booking: {
					...booking,
					price: newPrice,
					couponId
				},
				id: bookingId
			}
		});
	};

	newPrice = price => {
		const { booking } = this.state;
		this.setState({
			booking: {
				...booking,
				price
			}
		});
	};

	dismiss = () => {
		this.setState({ isOpen: false });
	};

	createCheckoutSession() {
		const { bookingId, booking, ambassador } = this.state;
		this.setState({ requestLoading: true });

		Dispatcher.dispatch({
			actionType: ActionTypes.CREATE_CHECKOUT_SESSION,
			data: { bookingId, booking, ambassador }
		});
	}

	checkTripisAvailable = status => {
		this.setState({
			validBooking: status
		});
	};

	onConfirm = () => {
		const { bookingId, email, isAgent } = this.state;
		if (isAgent && !email) {
			this.setState({
				isOpen: true,
				errorData: {
					message: 'Please input the traveler email.'
				}
			});
			return;
		}

		if (isAgent) {
			const firstName = getUserProfileName(email);
			firebase
				.auth()
				.createUserWithEmailAndPassword(email, 'newtraveler123')
				.then(credential => {
					// ReactPixel.fbq('track', 'NewUserBookingByConcierge');
					Dispatcher.dispatch({
						actionType: ActionTypes.ADD_USER_TO_BOOKING,
						data: {
							email,
							uid: credential.user.uid,
							firstName,
							isAmbassador: false,
							bookingId
						}
					});
				})
				.catch(function(error) {
					let errorCode = error.code;
					console.warn(error);
					// ReactPixel.fbq('track', 'ExistingUserBookingByConcierge');

					if (errorCode === 'auth/email-already-in-use') {
						Dispatcher.dispatch({
							actionType: ActionTypes.ADD_USER_TO_BOOKING,
							data: {
								email,
								firstName,
								isAmbassador: false,
								bookingId
							}
						});
						return;
					} else {
						this.setState({
							isOpen: true,
							errorData: error
						});
						return;
					}
				})
				.finally(() => {
					this.setState({ requestLoading: false });
				});
		}
		this.createCheckoutSession();
	};

	render() {
		const {
			booking,
			ambassador,
			isAgent,
			code,
			email,
			requestLoading,
			isOpen,
			errorData,
			validBooking
		} = this.state;

		if (isEmpty(booking) && !ambassador) {
			return <Loading></Loading>;
		}

		const { numAdults, numChildren, dates, conversion } = booking;

		return (
			<div id="booking-view">
				<Alert
					content={errorData.message}
					dismiss={() => this.dismiss}
					isOpen={isOpen}
				></Alert>
				<div className="profile-container">
					<span className="heading">Ambassador:</span>
					<div className="content">
						<div
							className="profile-photo small"
							style={{
								backgroundImage: 'url(' + ambassador.photo + ')'
							}}
						></div>
						<div className="info">
							<div style={{ fontWeight: 700, fontSize: 12 }}>
								{ambassador.firstName}
							</div>
							<span
								onClick={() => {
									this.props.history.push({
										pathname:
											'/user/' + booking.ambassadorId,
										state: {
											dates,
											adults: numAdults,
											kids: numChildren,
											conversion
										}
									});
								}}
							>
								View profile
							</span>
						</div>
					</div>
				</div>
				<div className="details-container">
					<div className="detail-item">
						<label>City:</label>
						<span>{booking.city}</span>
					</div>
					<div className="detail-item">
						<label>Dates:</label>
						<div>
							{booking.dates.map(date => {
								return (
									<span>
										{moment(date, BOOKING_DB_FORMAT).format(
											BOOKING_DISPLAY_FORMAT
										)}
										<br></br>
									</span>
								);
							})}
						</div>
					</div>
					{!isNil(numAdults) && !isNil(numChildren) && (
						<div className="detail-item">
							<label>Number of Travelers:</label>
							<div style={{ width: 150 }}>
								<GroupSizeHandler
									numAdults={numAdults}
									numChildren={numChildren}
									carType={ambassador.carType}
									handleTravelers={this.handleTravelers}
									checkTripisAvailable={
										this.checkTripisAvailable
									}
									price={booking.price}
									country={ambassador.country}
									newPrice={this.newPrice}
									oldConversion={conversion}
								></GroupSizeHandler>
							</div>
						</div>
					)}
					<div style={{ marginTop: 30, marginBottom: 7 }}>
						{booking.status !== 'completed' && (
							<div>
								<div className="detail-input">
									<Label>PROMO CODE:</Label>
									<div
										style={{
											display: 'flex',
											justifyContent: 'flex-end'
										}}
									>
										<Input
											id="code"
											value={code}
											onChange={this.handleChange}
										></Input>
										<Button onClick={this.redeemCode}>
											Redeem
										</Button>
									</div>
								</div>

								{isAgent && (
									<div className="detail-input">
										<Label>Traveler email:</Label>
										<div
											style={{
												display: 'flex',
												justifyContent: 'flex-end'
											}}
										>
											<Input
												id="email"
												type="email"
												value={email}
												onChange={this.handleChange}
											></Input>
										</div>
									</div>
								)}
							</div>
						)}
						<div className="detail-item price">
							<label>TOTAL:</label>
							<div>
								<span>USD ${booking.price}</span>
								<br></br>
								<span className="per-person-container">
									($
									{Math.trunc(
										booking.price /
											(numAdults + numChildren)
									)}{' '}
									per person)
								</span>
							</div>
						</div>
					</div>
				</div>
				{booking.status === 'completed' ? (
					<button
						className="button-primary"
						style={{ marginTop: 30 }}
						onClick={() =>
							this.props.history.push(
								'/trips/' + booking.itineraryId
							)
						}
					>
						View Trip
					</button>
				) : validBooking ? (
					<div>
						<div onClick={this.onConfirm}>
							<BtnLoader
								loading={requestLoading}
								text={'Confirm and Pay'}
							/>
						</div>
						<span style={{ width: '100%', fontSize: 12 }}>
							Pickup time and location can be selected after
							booking.<br></br>
							<a
								className="terms-conditions-link"
								href="https://global-uploads.webflow.com/5caf6429713e9d6b020cb773/5e19ba0071ed44125909af21_RedKnot%20T%26Cs%2011%20Jan.pdf"
								target="_blank"
								rel="noopener noreferrer"
							>
								<span>Terms & Conditions</span>
							</a>
						</span>
					</div>
				) : (
					'We are sorry but the number of travelers has exceeded the ones available for this ambassadors car. We encourage you to try a lower number or start a chat with us for a customized trip'
				)}
			</div>
		);
	}
}

export default Booking;
