import React, { Component } from 'react';
import firebase from 'firebase';
import { Input, Label, Progress } from 'reactstrap';
import FileUploader from 'react-firebase-file-uploader/lib/CustomUploadButton';
import MultipleDatePicker from 'react-multiple-datepicker';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { remove } from 'lodash';
import moment from 'moment';
import arrayMove from 'array-move';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faCamera,
	faMinusCircle,
	faPlusCircle,
	faDownload
} from '@fortawesome/free-solid-svg-icons';

import config from '../../config';
import Dispatcher from '../../common/dispatcher/index';
import UserStore from '../../common/stores/user';
import ActionTypes from '../../common/actions';
import EventTypes from '../../common/events';
import mailer from '../../common/mailer';

import { Loading } from '../utils/Loading';
import BtnLoader from '../utils/BtnLoader';

import {
	isOfAge,
	convertDate,
	isFileExtValid,
	areFieldsValid
} from '../../utils/Validation';
import { profileChangesToHtml } from '../../utils/Formatting';

import defaultPhoto from '../../assets/default-placeholder.png';
import {
	DOB_DISPLAY_EDIT_FORMAT,
	AVAILABILITY_DB_FORMAT
} from '../../consts/DateFormats';

import './profileEdit.scss';

const PROFILE_PICS_FOLDER = 'profile-pictures';
const LIFESTYLE_PICS_FOLDER = 'lifestyle-pictures';

class ProfileEdit extends Component {
	constructor() {
		super();
		const user = UserStore.getUser();
		const newDate = moment(
			convertDate(user['dob']),
			DOB_DISPLAY_EDIT_FORMAT
		);
		// example day helpers
		const exampleDay = user['exampleDay'];
		const dayTimes = exampleDay
			? Object.keys(exampleDay)
			: ['', '', '', '', ''];
		const dayActivities = exampleDay
			? Object.values(exampleDay)
			: ['', '', '', '', ''];

		this.state = {
			user,
			uid: user['uid'],
			firstName: user['firstName'],
			photo: user['photo'] || defaultPhoto,
			bio: user['bio'],
			city: user['city'],
			isAmbassador: user['isAmbassador'],
			availability: user['availability'] || {},
			carType: user['carType'] || null,
			lifestylePics: user['lifestylePics'] || [],
			gender: user['gender'],
			countryOrigin: user['countryOrigin'],
			tagline: user['tagline'],
			places: user['places'],
			fav: user['fav'],
			dayTimes,
			dayActivities,
			languages: user['languages'],
			dob: user['dob'],
			month: newDate.format('M'),
			day: newDate.format('D'),
			year: newDate.format('YYYY'),
			loading: false,
			validPhoto: false,
			requestLoading: false,
			progress: 0
		};
	}

	getSortableList() {
		const SortableList = SortableContainer(({ items }) => {
			return (
				<ul>
					{items.map((value, idx) => {
						const key = `sortable-item-${idx}`;
						return this.getSortableItem(key, idx, value);
					})}
				</ul>
			);
		});

		return (
			<SortableList
				items={this.state.lifestylePics}
				onSortEnd={this.onSortEnd}
				axis={'xy'}
			/>
		);
	}

	getSortableItem(key, idx, value) {
		const SortableItem = SortableElement(({ value }) => (
			<div
				className="sortable-lifestyle-pic"
				style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					position: 'relative',
					backgroundSize: 'cover',
					backgroundPosition: 'center',
					backgroundImage: "url('" + value.url + " ')"
				}}
			>
				<span>
					<FontAwesomeIcon
						icon={faMinusCircle}
						color={'#eb0029'}
						size={'2x'}
						onMouseOver={() => this.deletePicture(value.filename)}
					/>
				</span>
			</div>
		));

		return <SortableItem key={key} index={idx} value={value} />;
	}

	componentDidMount() {
		window.scrollTo(0, 0);
		UserStore.addListener(EventTypes.USER_UPDATED, this.onUserUpdated);
	}

	componentWillUnmount() {
		UserStore.removeListener(EventTypes.USER_UPDATED, this.onUserUpdated);
	}

	onUserUpdated = () => {
		this.setState({ requestLoading: false });
		const { uid, firstName, isAmbassador, changesHtml } = this.state;

		if (config.isProd && isAmbassador) {
			mailer.sendProfileUpdateNotification(firstName, uid, changesHtml);
		}
		this.props.history.push('/profile');
	};

	getAvailability = () => {
		const userDates = this.state.availability;
		const dateKeys = Object.keys(this.state.availability);
		let availableDates = [];
		let unavailableDates = [];
		let formattedDate;
		dateKeys.forEach(date => {
			// TODO: refactor
			formattedDate = new Date(
				moment(date, AVAILABILITY_DB_FORMAT).format('DD MMMM YYYY')
			);
			userDates[date]
				? availableDates.push(formattedDate)
				: unavailableDates.push(formattedDate);
		});

		return { availableDates, unavailableDates };
	};

	handleChange = event => {
		const key = event.target.id;
		const value = event.target.value;
		this.setState({ [key]: value });
	};

	handleUploadStart = file => {
		isFileExtValid(file.name)
			? this.setState({
					validPhoto: true
			  })
			: this.setState({ validPhoto: false });
	};

	handleUploadSuccess = filename => {
		if (this.state.validPhoto) {
			firebase
				.storage()
				.ref(PROFILE_PICS_FOLDER)
				.child(filename)
				.getDownloadURL()
				.then(url => {
					this.setState({ photo: url });
				});
		}
	};

	handleLifestyleUploadSuccess = filename => {
		if (this.state.validPhoto) {
			firebase
				.storage()
				.ref(LIFESTYLE_PICS_FOLDER)
				.child(filename)
				.getDownloadURL()
				.then(url => {
					this.setState({
						lifestylePics: [
							...this.state.lifestylePics,
							{ url, filename }
						]
					});
				});
		}
	};

	handleExampleDayTime = event => {
		const { dayTimes } = this.state;
		const time = event.target.value;
		const idx = event.target.id;

		dayTimes[idx] = time;
		this.setState({
			dayTimes
		});
	};

	handleExampleDayActivity = event => {
		const { dayActivities } = this.state;
		const activity = event.target.value;
		const idx = event.target.id;

		dayActivities[idx] = activity;
		this.setState({
			dayActivities
		});
	};

	buildExampleDay = () => {
		const { dayTimes, dayActivities } = this.state;
		let exampleDay = {};

		dayTimes.forEach((time, idx) => {
			exampleDay = {
				...exampleDay,
				[time]: dayActivities[idx]
			};
		});

		return exampleDay;
	};

	handleAvailability = dates => {
		let availability = {};
		let formattedDate;
		dates.forEach(date => {
			formattedDate = moment(date).format(AVAILABILITY_DB_FORMAT);
			availability[formattedDate] = true;
		});
		this.setState({
			availability
		});
	};

	handleProgress = progress => this.setState({ progress });

	onSortEnd = ({ oldIndex, newIndex }) => {
		this.setState(({ lifestylePics }) => ({
			lifestylePics: arrayMove(lifestylePics, oldIndex, newIndex)
		}));
	};

	handleSubmit = event => {
		event.preventDefault();

		const {
			user,
			year,
			day,
			loading,
			month,
			validPhoto,
			requestLoading,
			progress,
			dayTimes,
			dayActivities,
			...profile
		} = this.state;

		const dob = new Date(
			this.state.year,
			this.state.month - 1,
			this.state.day
		);

		if (!areFieldsValid({ profile })) {
			return;
		}

		if (!this.state.isAmbassador) {
			if (!isOfAge(dob)) {
				return;
			}
			this.setState({
				dob
			});

			profile['dob'] = dob;
		}

		if (this.state.isAmbassador) {
			profile['exampleDay'] = this.buildExampleDay();
		}

		const changesHtml = profileChangesToHtml(user, profile);

		this.setState({ requestLoading: true, changesHtml });
		Dispatcher.dispatch({
			actionType: ActionTypes.UPDATE_USER,
			data: profile
		});
	};

	deletePicture = filename => {
		firebase
			.storage()
			.ref(LIFESTYLE_PICS_FOLDER)
			.child(filename)
			.delete()
			.then(() => {
				const temp = this.state.lifestylePics;
				remove(temp, pic => {
					return pic.filename === filename;
				});
				this.setState({
					lifestylePics: temp
				});
			});
	};

	toDatepicker(datesArray) {
		const dateStrings = Object.keys(datesArray);
		let dates = [];

		dateStrings.forEach(date => {
			dates.push(new Date(date));
		});

		return dates;
	}

	render() {
		let {
			firstName,
			photo,
			bio,
			isAmbassador,
			gender,
			countryOrigin,
			languages,
			carType,
			tagline,
			places,
			fav,
			dayTimes,
			dayActivities,
			loading,
			day,
			month,
			year,
			requestLoading,
			progress
		} = this.state;

		const { availableDates, unavailableDates } = this.getAvailability();

		if (loading) {
			return <Loading></Loading>;
		}

		return (
			<form id="profile-edit" onSubmit={this.handleSubmit}>
				<FileUploader
					accept="image/*"
					filename={this.state.uid}
					storageRef={firebase.storage().ref(PROFILE_PICS_FOLDER)}
					onUploadStart={this.handleUploadStart}
					onUploadSuccess={this.handleUploadSuccess}
					hidden={true}
				>
					<div className="profile-photo">
						{photo ? (
							<div
								style={{
									width: 150,
									height: 150,
									borderRadius: '100%',
									backgroundImage: 'url(' + photo + ')',
									margin: '0 auto',
									backgroundSize: 'cover',
									backgroundPosition: 'top'
								}}
							></div>
						) : (
							<div
								style={{
									width: 150,
									height: 150,
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									border: '1px solid gray',
									borderRadius: '100%',
									margin: '0 auto',
									fontFamily: 'Open Sans',
									fontWeight: 900,
									fontSize: 22,
									color: 'gray'
								}}
							>
								Your Photo
							</div>
						)}
						<div className="upload-photo-icon">
							<FontAwesomeIcon icon={faCamera} />
						</div>
					</div>
				</FileUploader>
				<div className="user-input">
					<Label>Name</Label>
					<Input
						id="firstName"
						value={firstName}
						onChange={this.handleChange}
						required
					></Input>
				</div>
				<div className="user-input">
					<Label>Gender</Label>
					<Input
						id="gender"
						type="select"
						value={gender}
						onChange={this.handleChange}
						required
					>
						<option></option>
						<option value="female">Female</option>
						<option value="male">Male</option>
						<option value="other">Other</option>
					</Input>
				</div>
				<div className="user-input">
					<Label>Country of origin</Label>
					<Input
						id="countryOrigin"
						value={countryOrigin}
						onChange={this.handleChange}
						required
					></Input>
				</div>
				{!isAmbassador && (
					<div className="user-input">
						<Label>Date of birth</Label>
						<div className="bday-container">
							<Input
								id="day"
								type="number"
								min={1}
								max={31}
								value={day}
								placeholder="DD"
								onChange={this.handleChange}
								required
							></Input>
							<Input
								id="month"
								type="number"
								min={1}
								max={12}
								value={month}
								placeholder="MM"
								onChange={this.handleChange}
								required
							></Input>
							<Input
								id="year"
								type="number"
								value={year}
								placeholder="YYYY"
								onChange={this.handleChange}
								required
							></Input>
						</div>
					</div>
				)}
				<div className="user-input">
					<Label>Tell us about yourself</Label>
					<textarea
						id="bio"
						value={bio}
						onChange={this.handleChange}
						required
					></textarea>
				</div>
				{isAmbassador && (
					<>
						<div className="user-input">
							<Label>Car capacity</Label>
							<Input
								id="carType"
								type="select"
								value={carType}
								onChange={this.handleChange}
								required
							>
								<option></option>
								<option value="car">
									Car (up to 4 free seats)
								</option>
								<option value="suv">
									SUV (up to 10 free seats)
								</option>
								<option value="van">
									Van (up to 14 free seats)
								</option>
							</Input>
						</div>
						<div className="user-input">
							<Label>Headline</Label>
							<Input
								id="tagline"
								value={tagline}
								onChange={this.handleChange}
								required
							></Input>
						</div>
						<div className="user-input">
							<Label>Languages you speak</Label>
							<Input
								id="languages"
								value={languages}
								onChange={this.handleChange}
								required
							></Input>
						</div>
						<div className="user-input">
							<Label>Places you've been</Label>
							<textarea
								id="places"
								value={places}
								onChange={this.handleChange}
							></textarea>
						</div>
						<div className="user-input">
							<Label>
								Your favorite things to do in your country
							</Label>
							<textarea
								id="fav"
								value={fav}
								onChange={this.handleChange}
							></textarea>
						</div>
						<div className="user-input">
							<Label>What a day with you would look like</Label>
							<div className="d-flex flex-column">
								{dayTimes.map((time, idx) => {
									return (
										<div className="example-day-entry">
											<Input
												id={idx}
												type="time"
												value={time}
												placeholder="00:00"
												onChange={
													this.handleExampleDayTime
												}
												required
											></Input>
											<Input
												id={idx}
												type="text"
												value={dayActivities[idx]}
												placeholder="Activity"
												onChange={
													this
														.handleExampleDayActivity
												}
												required
											></Input>
										</div>
									);
								})}
							</div>
						</div>
						<div className="user-input">
							<Label>Pictures of your lifestyle</Label>
							<div className="lifestyle-container">
								{this.getSortableList()}
							</div>
						</div>
						<div
							style={{
								textAlign: 'right',
								color: '#eb0029',
								fontWeight: 'bold',
								fontSize: '11px'
							}}
						>
							<FileUploader
								accept="image/*"
								multiple
								filename={file =>
									this.state.uid +
									'-' +
									file.name.split('.')[0]
								}
								storageRef={firebase
									.storage()
									.ref(LIFESTYLE_PICS_FOLDER)}
								onUploadStart={this.handleUploadStart}
								onUploadSuccess={
									this.handleLifestyleUploadSuccess
								}
								onProgress={this.handleProgress}
								hidden={true}
							>
								<div
									style={{
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center'
									}}
								>
									<span>Add Photo</span>{' '}
									<FontAwesomeIcon
										style={{ marginLeft: 5 }}
										icon={faPlusCircle}
										color={'#eb0029'}
										size={'lg'}
									/>
								</div>
							</FileUploader>
							{progress > 0 && (
								<Progress value={progress} color="success">
									{progress + '%'}
								</Progress>
							)}
						</div>
						<div className="user-input">
							<Label>Your availability</Label>
							<MultipleDatePicker
								minDate={new Date()}
								selectedDates={availableDates}
								disabledDates={unavailableDates}
								onSubmit={dates =>
									this.handleAvailability(dates)
								}
							/>
						</div>
					</>
				)}
				<BtnLoader
					loading={requestLoading}
					text={'Save Changes'}
					icon={faDownload}
				></BtnLoader>
			</form>
		);
	}
}

export default ProfileEdit;
