import React, { Component } from 'react';
import { isEmpty } from 'lodash';
import { Badge } from 'reactstrap';

import Dispatcher from '../../common/dispatcher/index';
import EventTypes from '../../common/events';
import ActionTypes from '../../common/actions';
import UserStore from '../../common/stores/user';

import { Loading } from '../utils/Loading';

import { getOrderedDates } from '../../utils/Validation';

class Trips extends Component {
	constructor() {
		super();
		const user = UserStore.getUser();
		const isAmbassador = user.isAmbassador;
		const isAgent = user.isAgent;
		const userType = UserStore.getRole();

		this.state = {
			itineraries: null,
			isAmbassador,
			isAgent
		};

		Dispatcher.dispatch({
			actionType: ActionTypes.GET_ITINERARIES,
			data: {
				uid: user.uid,
				userType
			}
		});
	}

	componentDidMount() {
		UserStore.addListener(
			EventTypes.ITINERARIES_LOADED,
			this.onTitinerariesLoaded
		);
	}

	componentWillUnmount() {
		UserStore.removeListener(
			EventTypes.ITINERARIES_LOADED,
			this.onTitinerariesLoaded
		);
	}

	onTitinerariesLoaded = () => {
		const itineraries = UserStore.getItineraries();
		this.setState({
			itineraries
		});
	};

	render() {
		const { isAmbassador, isAgent, itineraries } = this.state;
		const today = new Date().setHours(0, 0, 0, 0);

		if (!itineraries) {
			return <Loading></Loading>;
		}

		if (isEmpty(itineraries)) {
			return <p>You have no trips yet.</p>;
		}

		itineraries.sort((i1, i2) => {
			const dates1 = getOrderedDates(i1.days);
			const dates2 = getOrderedDates(i2.days);

			return (
				new Date(dates1[0]).getTime() - new Date(dates2[0]).getTime()
			);
		});

		return (
			<>
				<div>
					<hr
						style={{
							borderRadius: '100%',
							border: 'solid 1px #D8D8D8'
						}}
					></hr>
					{itineraries.map(itinerary => {
						const dates = getOrderedDates(itinerary.days);

						const start = dates[0];
						const end = dates[dates.length - 1];
						const endDateTime = new Date(end).getTime();

						let profile;
						if (isAmbassador || isAgent) {
							profile = itinerary.traveler;
						} else {
							profile = itinerary.ambassador;
						}

						return (
							<div
								onClick={() =>
									this.props.history.push(
										'/trips/' + itinerary.id
									)
								}
								style={{ marginBottom: 20 }}
							>
								<div
									style={{
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'space-between'
									}}
								>
									<div
										style={{
											display: 'flex',
											alignItems: 'center',
											cursor: 'pointer'
										}}
									>
										<div
											style={{
												width: 50,
												height: 50,
												borderRadius: '100%',
												backgroundImage:
													'url(' +
													profile.photo +
													')',
												margin: '0 auto',
												backgroundSize: 'cover',
												backgroundPosition: 'top'
											}}
										></div>
										<div style={{ fontWeight: 600 }}>
											<div>{itinerary.city}</div>
											{endDateTime < today ? (
												<Badge color="danger">
													Finalized
												</Badge>
											) : itinerary.isConfirmed ? (
												<Badge color="success">
													Confirmed
												</Badge>
											) : (
												<Badge
													color="warning"
													style={{ color: 'white' }}
												>
													Booked
												</Badge>
											)}
										</div>
									</div>

									<div
										style={{
											display: 'flex',
											flexDirection: 'column',
											fontSize: 11
										}}
									>
										{start === end ? (
											<span>{start}</span>
										) : (
											<>
												<span>{start}</span> to
												<span>{end}</span>
											</>
										)}
									</div>
								</div>
							</div>
						);
					})}
				</div>
			</>
		);
	}
}

export default Trips;
