import React from 'react';
import { isNil } from 'lodash';

import { priceRange } from '../utils/priceRange';
import './groupSizeHandler.scss';

export const GroupSizeHandler = ({
	numAdults,
	numChildren,
	handleTravelers,
	checkTripisAvailable,
	carType,
	price,
	country,
	oldConversion
}) => {
	if (isNil(numAdults) || isNil(numChildren)) {
		return null;
	}

	const increaseAdults = () => {
		let adults = parseInt(numAdults || 0) + 1;
		adults = adults > 14 ? 14 : adults;

		if (numAdults < 14) {
			let Newprice = newPricePerPerson(
				country,
				adults,
				numChildren,
				price,
				'inc'
			);

			handleTravelers({
				numAdults: adults,
				numChildren: numChildren,
				price: Newprice.price,
				conversion: Newprice.conversion
			});
			validateTrip(carType, adults, numChildren);
		}
	};

	const decreaseAdults = () => {
		let adults = parseInt(numAdults || 0) - 1;
		adults = adults < 1 ? 1 : adults;
		if (numAdults > 1) {
			let Newprice = newPricePerPerson(
				country,
				adults,
				numChildren,
				price,
				'dec'
			);
			handleTravelers({
				numAdults: adults,
				numChildren: numChildren,
				price: Newprice.price,
				conversion: Newprice.conversion
			});
			validateTrip(carType, adults, numChildren);
		}
	};

	const increaseKids = () => {
		let kids = parseInt(numChildren || 0) + 1;
		kids = kids > 14 ? 14 : kids;
		if (numChildren < 14) {
			let Newprice = newPricePerPerson(
				country,
				numAdults,
				kids,
				price,
				'inc'
			);
			handleTravelers({
				numAdults: numAdults,
				numChildren: kids,
				price: Newprice.price,
				conversion: Newprice.conversion
			});
			validateTrip(carType, numAdults, kids);
		}
	};

	const decreaseKids = () => {
		let kids = parseInt(numChildren || 0) - 1;
		kids = kids < 1 ? 0 : kids;
		if (numChildren > 0) {
			let Newprice = newPricePerPerson(
				country,
				numAdults,
				kids,
				price,
				'dec'
			);
			handleTravelers({
				numAdults: numAdults,
				numChildren: kids,
				price: Newprice.price,
				conversion: Newprice.conversion
			});
			validateTrip(carType, numAdults, kids);
		}
	};

	const validateTrip = (carType, numAdults, numChildren) => {
		let size = 0;
		const totalTravelers = parseInt(numAdults) + parseInt(numChildren);
		switch (carType) {
			case 'car':
				size = 4;
				break;
			case 'suv':
				size = 8;
				break;
			case 'van':
				size = 14;
				break;
			default:
				break;
		}
		if (totalTravelers > size) {
			checkTripisAvailable(false);
		} else {
			checkTripisAvailable(true);
		}
	};

	const newPricePerPerson = (
		country,
		numAdults,
		numChildren,
		price,
		flag
	) => {
		let numTravelers = parseInt(numAdults) + parseInt(numChildren);
		let conversion = priceRange(country, numTravelers);
		let pricePerPerson =
			flag === 'inc'
				? price + conversion
				: price - priceRange(country, numTravelers + 1);
		return {
			price: pricePerPerson,
			conversion:
				flag === 'inc'
					? oldConversion + conversion
					: oldConversion - priceRange(country, numTravelers + 1)
		};
	};

	return (
		<>
			<div className="num-travelers-container">
				<div>
					<div>
						<span className="btn-decrease" onClick={decreaseAdults}>
							-
						</span>
						<span className="number">{numAdults}</span>
						<span className="btn-increase" onClick={increaseAdults}>
							+
						</span>
					</div>
				</div>
				<div>
					<div>
						<span className="btn-decrease" onClick={decreaseKids}>
							-
						</span>
						<span className="number">
							{numChildren === 0 ? 'Kids' : numChildren}
						</span>
						<span className="btn-increase" onClick={increaseKids}>
							+
						</span>
					</div>
				</div>
			</div>
		</>
	);
};
