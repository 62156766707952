const isProdEnv =
	window.location.hostname === 'redknot-abe04.firebaseapp.com' ||
	window.location.hostname === 'app.goredknot.com';
const isDevEnv = window.location.hostname.includes('localhost');
// console.log(`isProdEnv? ${isProdEnv} isDevEnv? ${isDevEnv}`);

const devConfig = {
	apiKey: 'AIzaSyAXqKRbP6NgEPo77o-BufbEZoFDW8-lYWI',
	projectId: 'redknot-staging',
	authDomain: 'redknot-staging.firebaseapp.com',
	storageBucket: 'gs://redknot-staging.appspot.com/',
	// TODO: backend and gql should be localhost for dev
	backendUrl: 'https://us-central1-redknot-staging.cloudfunctions.net/app',
	graphqlUrl:
		'https://us-central1-redknot-staging.cloudfunctions.net/app/gql',
	stripeKey: 'pk_test_vJ9IKmxasDaeCAqBadeSMPGJ00p2D5BDQ4',
	fbPixel: '',
	isProd: false
};

const stagingConfig = Object.assign({}, devConfig, {
	backendUrl: 'https://us-central1-redknot-staging.cloudfunctions.net/app',
	graphqlUrl: 'https://us-central1-redknot-staging.cloudfunctions.net/app/gql'
});

const prodConfig = {
	apiKey: 'AIzaSyAIyi9eLze7ydPMKHlloOPUA_kur3biBag',
	projectId: 'redknot-abe04',
	authDomain: 'redknot-abe04.firebaseapp.com',
	storageBucket: 'gs://redknot-abe04.appspot.com/',
	backendUrl: 'https://us-central1-redknot-abe04.cloudfunctions.net/app',
	graphqlUrl: 'https://us-central1-redknot-abe04.cloudfunctions.net/app/gql',
	stripeKey: 'pk_live_TXXz3qOk7ZKaS2fuVZomABRe00GfqOYVc3',
	fbPixel: '2720415004652279',
	isProd: true
};

let config;
if (isProdEnv) {
	config = prodConfig;
} else if (isDevEnv) {
	config = devConfig;
} else {
	config = stagingConfig;
}
export default config;
