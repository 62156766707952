import React, { Component } from 'react';
import { Button } from 'reactstrap';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faClock } from '@fortawesome/free-solid-svg-icons';

import Dispatcher from '../../common/dispatcher/index';
import EventTypes from '../../common/events';
import ActionTypes from '../../common/actions';
import UserStore from '../../common/stores/user';

import { Loading } from '../utils/Loading';
import BtnLoader from '../utils/BtnLoader';

import { getOrderedDates } from '../../utils/Validation';

import GroupSizeDisplay from '../blocks/GroupSizeDisplay';

import {
	ITINERARY_DB_FORMAT,
	ITINERARY_DISPLAY_FORMAT
} from '../../consts/DateFormats';

import './itinerary.scss';

class Itinerary extends Component {
	constructor(props) {
		super();
		const id = props.match.params.id;
		const user = UserStore.getUser();

		this.state = {
			user,
			activeDay: 1,
			booking: UserStore.getBooking(),
			itinerary: UserStore.getItinerary(),
			requestLoading: false
		};

		// TODO: maybe only get itinerary when id's are different?
		Dispatcher.dispatch({
			actionType: ActionTypes.GET_ITINERARY,
			data: id
		});
	}

	componentDidMount() {
		UserStore.addListener(
			EventTypes.ITINERARY_LOADED,
			this.onItineraryLoaded
		);
		UserStore.addListener(
			EventTypes.ITINERARY_SAVED,
			this.onItinerarySaved
		);
	}

	componentWillUnmount() {
		UserStore.removeListener(
			EventTypes.ITINERARY_LOADED,
			this.onItineraryLoaded
		);
		UserStore.removeListener(
			EventTypes.ITINERARY_SAVED,
			this.onItinerarySaved
		);
	}

	onItineraryLoaded = () => {
		const { user } = this.state;
		const itinerary = UserStore.getItinerary();
		const dayKeys = Object.keys(itinerary.days);
		const lastTripDate = new Date(dayKeys[dayKeys.length - 1]);
		const postTripDate = new Date(lastTripDate);
		// TODO: use correct timezone instead of adding 2 days to the date
		postTripDate.setDate(lastTripDate.getDate() + 2);
		const today = new Date();
		const hasTripEnded = today.getTime() > postTripDate.getTime();

		// TODO: find a better way to only save certain fields of the itinerary
		// (the API returns the whole ambassador and traveler and we don't want to save it to the DB)
		const {
			id,
			ambassadorId,
			travelerId,
			chatId,
			city,
			days,
			numAdults,
			numChildren
		} = itinerary;

		this.setState({
			itinerary: {
				id,
				ambassadorId,
				travelerId,
				chatId,
				city,
				days,
				numAdults,
				numChildren
			},
			ambassador: itinerary.ambassador,
			traveler: itinerary.traveler,
			isAmbassador: user.uid === itinerary.ambassadorId,
			isAgent: user.isAgent,
			// TODO: check if last day -> date conversion is OK
			hasTripEnded
		});
	};

	setActiveDay(event) {
		const day = event.target.value;
		this.setState({
			activeDay: day
		});
	}

	onDetailChange = (e, date) => {
		const key = e.target.id;
		this.setState({
			itinerary: {
				...this.state.itinerary,
				days: {
					...this.state.itinerary.days,
					[date]: {
						...this.state.itinerary.days[date],
						[key]: e.target.value
					}
				}
			}
		});
	};

	saveItinerary = () => {
		this.setState({ requestLoading: true });
		Dispatcher.dispatch({
			actionType: ActionTypes.SAVE_ITINERARY,
			data: this.state.itinerary
		});
	};

	onItinerarySaved = () => {
		this.setState({ requestLoading: false });
		alert('itinerary succesfully saved');
	};

	render() {
		const {
			itinerary,
			ambassador,
			traveler,
			hasTripEnded,
			isAmbassador,
			isAgent,
			requestLoading
		} = this.state;
		if ((!ambassador && !traveler) || !itinerary) {
			return <Loading></Loading>;
		}

		const { numAdults, numChildren } = itinerary;
		const dayKeys = getOrderedDates(itinerary.days);

		if (isAmbassador) {
			return (
				<div>
					<h3>Your trip with {traveler.firstName}</h3>
					<GroupSizeDisplay
						numAdults={numAdults}
						numChildren={numChildren}
					></GroupSizeDisplay>
					<div style={{ textAlign: 'left' }}>
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								margin: '10px 0'
							}}
						>
							<div
								style={{
									width: 53,
									height: 53,
									borderRadius: '100%',
									backgroundImage:
										'url(' + traveler.photo + ')',
									backgroundSize: 'cover',
									backgroundPosition: 'top'
								}}
							></div>
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									alignItems: 'flex-start',
									marginLeft: 10
								}}
							>
								<span style={{ fontWeight: 700, fontSize: 10 }}>
									{traveler.firstName}
								</span>
								{!hasTripEnded && (
									<span
										onClick={() => {
											this.props.history.push(
												'/chat/' + itinerary.chatId
											);
										}}
										style={{
											fontWeight: 500,
											fontSize: 9,
											color: '#eb0029',
											cursor: 'pointer'
										}}
									>
										Chat with {traveler.firstName}
									</span>
								)}
							</div>
						</div>
					</div>
					<div>
						<div
							style={{
								display: 'flex',
								justifyContent: 'space-evenly',
								marginTop: 15
							}}
						>
							{dayKeys.map((date, idx) => {
								return (
									<div style={{ textAlign: 'center' }}>
										<Button
											className="itinerary-day-box"
											active={
												this.state.activeDay == idx + 1
											}
											onClick={e => this.setActiveDay(e)}
											value={idx + 1}
											style={{ backgroundColor: 'none' }}
										>
											Day {idx + 1}
											<div style={{ fontSize: 9 }}>
												{moment(
													date,
													ITINERARY_DB_FORMAT
												).format(
													ITINERARY_DISPLAY_FORMAT
												)}
											</div>
										</Button>
									</div>
								);
							})}
						</div>
					</div>
					<hr
						style={{
							borderRadius: '100%',
							border: 'solid 1px #D8D8D8'
						}}
					></hr>
					{dayKeys.map((date, idx) => {
						const day = itinerary.days[date];
						return (
							<div
								className={
									this.state.activeDay == idx + 1
										? 'itinerary-day-detail selected'
										: 'itinerary-day-detail'
								}
							>
								<div>
									<div
										style={{
											display: 'flex',
											justifyContent: 'space-around'
										}}
									>
										<div
											style={{
												width: '30%',
												fontSize: 10
											}}
										>
											<div
												style={{
													display: 'flex',
													justifyContent: 'center',
													marginBottom: 5,
													fontWeight: 600
												}}
											>
												<FontAwesomeIcon
													style={{
														marginRight: '5px'
													}}
													icon={faMapMarkerAlt}
												/>
												<span>meeting point</span>
											</div>
											<div>{day.meeting_point}</div>
										</div>
										<div
											style={{
												width: '30%',
												fontSize: 10
											}}
										>
											<div
												style={{
													display: 'flex',
													justifyContent: 'center',
													marginBottom: 5,
													fontWeight: 600
												}}
											>
												<FontAwesomeIcon
													style={{
														marginRight: '5px'
													}}
													icon={faClock}
												/>
												<span>meeting time</span>
											</div>
											{/* TODO: handle as time input */}
											<div>{day.meeting_time}</div>
										</div>
									</div>
									{!hasTripEnded ? (
										<div>
											<div
												style={{
													textAlign: 'left',
													marginTop: 30
												}}
											>
												<span
													style={{
														fontSize: 12,
														fontWeight: 600
													}}
												>
													Additional Information:
												</span>
												<textarea
													id="info"
													value={day.info}
													style={{
														width: '100%',
														height: 100
													}}
													onChange={e =>
														this.onDetailChange(
															e,
															date
														)
													}
												></textarea>
											</div>
											<div
												style={{
													textAlign: 'left',
													marginTop: 30
												}}
											>
												<span
													style={{
														fontSize: 12,
														fontWeight: 600
													}}
												>
													Activities:
												</span>
												<textarea
													id="activities"
													value={day.activities}
													style={{
														width: '100%',
														height: 200
													}}
													onChange={e =>
														this.onDetailChange(
															e,
															date
														)
													}
												></textarea>
											</div>
										</div>
									) : (
										<div>
											<div
												style={{
													textAlign: 'left',
													marginTop: 30
												}}
											>
												<span
													style={{
														fontSize: 12,
														fontWeight: 600
													}}
												>
													Additional Information:
												</span>
												<p>{day.info}</p>
											</div>
											<div
												style={{
													textAlign: 'left',
													marginTop: 30
												}}
											>
												<span
													style={{
														fontSize: 12,
														fontWeight: 600
													}}
												>
													Activities:
												</span>
												<p>{day.activities}</p>
											</div>
										</div>
									)}
								</div>
							</div>
						);
					})}
					{!hasTripEnded ? (
						<div onClick={this.saveItinerary}>
							<BtnLoader
								loading={requestLoading}
								text={'save itinerary'}
							/>
						</div>
					) : (
						<button
							className="button-primary"
							style={{ marginTop: 30 }}
							onClick={() =>
								this.props.history.push(
									'/reviews/' + itinerary.id
								)
							}
						>
							See your reviews
						</button>
					)}
				</div>
			);
		} else {
			return (
				<div>
					<h3>Your trip to {itinerary.city}</h3>
					<GroupSizeDisplay
						numAdults={numAdults}
						numChildren={numChildren}
					></GroupSizeDisplay>
					<div style={{ textAlign: 'left' }}>
						<span style={{ fontSize: 12, fontWeight: 600 }}>
							Ambassador:
						</span>
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								margin: '10px 0'
							}}
						>
							<div
								onClick={() => {
									this.props.history.push(
										'/user/' + ambassador.uid
									);
								}}
								style={{
									width: 53,
									height: 53,
									cursor: 'pointer',
									borderRadius: '100%',
									backgroundImage:
										'url(' + ambassador.photo + ')',
									backgroundSize: 'cover',
									backgroundPosition: 'top'
								}}
							></div>
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									alignItems: 'flex-start',
									marginLeft: 10
								}}
							>
								<span style={{ fontWeight: 700, fontSize: 10 }}>
									{ambassador.firstName}
								</span>
								{hasTripEnded ? (
									<span
										onClick={() => {
											this.props.history.push(
												'/reviews/' + itinerary.id
											);
										}}
										style={{
											fontWeight: 500,
											fontSize: 9,
											color: '#eb0029'
										}}
									>
										Review {ambassador.firstName}
									</span>
								) : (
									<span
										onClick={() => {
											this.props.history.push(
												'/chat/' + itinerary.chatId
											);
										}}
										style={{
											fontWeight: 500,
											fontSize: 9,
											color: '#eb0029',
											cursor: 'pointer'
										}}
									>
										Chat with {ambassador.firstName}
									</span>
								)}
							</div>
						</div>
					</div>
					<div>
						<div
							style={{
								display: 'flex',
								justifyContent: 'space-evenly',
								marginTop: 15
							}}
						>
							{dayKeys.map((date, idx) => {
								return (
									<div style={{ textAlign: 'center' }}>
										<Button
											className="itinerary-day-box"
											active={
												this.state.activeDay == idx + 1
											}
											onClick={e => this.setActiveDay(e)}
											value={idx + 1}
										>
											Day {idx + 1}
											<div style={{ fontSize: 9 }}>
												{moment(
													date,
													ITINERARY_DB_FORMAT
												).format(
													ITINERARY_DISPLAY_FORMAT
												)}
											</div>
										</Button>
									</div>
								);
							})}
						</div>
					</div>
					<hr
						style={{
							borderRadius: '100%',
							border: 'solid 1px #D8D8D8'
						}}
					></hr>
					{dayKeys.map((date, idx) => {
						const day = itinerary.days[date];
						return (
							<div
								className={
									this.state.activeDay == idx + 1
										? 'itinerary-day-detail.selected'
										: 'itinerary-day-detail'
								}
							>
								<div
									style={{
										marginTop: 20,
										marginBottom: 20,
										fontSize: 12,
										color: 'gray'
									}}
								>
									Tell {ambassador.firstName} where and when
									to pick you up (for example, in the lobby of
									your hotel at 9am).
								</div>
								<div
									style={{
										display: 'flex',
										justifyContent: 'space-around'
									}}
								>
									<div style={{ width: '30%', fontSize: 10 }}>
										<div
											style={{
												display: 'flex',
												justifyContent: 'center',
												marginBottom: 5,
												fontWeight: 600
											}}
										>
											<FontAwesomeIcon
												style={{ marginRight: '5px' }}
												icon={faMapMarkerAlt}
											/>
											<span>meeting point</span>
										</div>
										<div>
											{!hasTripEnded && !isAgent ? (
												<input
													id="meeting_point"
													value={day.meeting_point}
													onChange={e =>
														this.onDetailChange(
															e,
															date
														)
													}
													style={{
														textAlign: 'center',
														width: '100%'
													}}
												></input>
											) : (
												<div>{day.meeting_point}</div>
											)}
										</div>
									</div>
									<div style={{ width: '30%', fontSize: 10 }}>
										<div
											style={{
												display: 'flex',
												justifyContent: 'center',
												marginBottom: 5,
												fontWeight: 600
											}}
										>
											<FontAwesomeIcon
												icon={faClock}
												style={{ marginRight: '5px' }}
											/>
											<span>meeting time</span>
										</div>
										<div>
											{!hasTripEnded && !isAgent ? (
												<input
													id="meeting_time"
													value={day.meeting_time}
													onChange={e =>
														this.onDetailChange(
															e,
															date
														)
													}
													style={{
														textAlign: 'center',
														width: '100%'
													}}
												></input>
											) : (
												<div>{day.meeting_time}</div>
											)}
										</div>
									</div>
								</div>
								<div
									style={{ textAlign: 'left', marginTop: 30 }}
								>
									<span
										style={{
											fontSize: 12,
											fontWeight: 600
										}}
									>
										Additional Information:
									</span>
									<p>{day.info}</p>
								</div>
								<div
									style={{ textAlign: 'left', marginTop: 30 }}
								>
									<span
										style={{
											fontSize: 12,
											fontWeight: 600
										}}
									>
										Activities:
									</span>
									{day.activities ? (
										<p>{day.activities}</p>
									) : (
										<p>
											Chat with your ambassador to plan
											your trip!
										</p>
									)}
								</div>
								{!hasTripEnded ? (
									<div onClick={this.saveItinerary}>
										<BtnLoader
											loading={requestLoading}
											text={'save itinerary'}
										/>
									</div>
								) : (
									<button
										className="button-primary"
										onClick={() =>
											this.props.history.push(
												'/reviews/' + itinerary.id
											)
										}
									>
										Review Your Experience
									</button>
								)}
							</div>
						);
					})}
				</div>
			);
		}
	}
}

export default Itinerary;
