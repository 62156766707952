import React from 'react';
import { Spinner } from 'reactstrap';
import './loading.scss';

export const Loading = () => {
	return (
		<div id="spinner-container">
			<Spinner />
		</div>
	);
};
