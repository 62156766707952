import React, { Component } from 'react';
import firebase from 'firebase';
import { Input, InputGroupAddon, InputGroup } from 'reactstrap';

import Dispatcher from '../../common/dispatcher';
import UserStore from '../../common/stores/user';
import EventTypes from '../../common/events';
import ActionTypes from '../../common/actions';
import ReactPixel from '../../common/FbPixel';

import { Loading } from '../utils/Loading';
import BtnLoader from '../utils/BtnLoader';
import Alert from '../utils/Alert';

import { getUserProfileName } from '../../utils/Validation';

import google from '../../assets/google-01-min.png';
import facebook from '../../assets/facebook.png';

import './auth.scss';

class Signup extends Component {
	constructor() {
		super();
		this.state = {
			email: '',
			password: '',
			name: '',
			isAuthed: UserStore.isAuthed(),
			isLoading: false,
			requestLoading: false,
			isOpen: false,
			errorData: {}
		};
	}

	componentDidMount() {
		UserStore.addListener(EventTypes.USER_LOADED, this.onUserLoaded);
		ReactPixel.pageView();
	}

	componentWillUnmount() {
		UserStore.removeListener(EventTypes.USER_LOADED, this.onUserLoaded);
	}

	onUserLoaded = () => {
		const isAuthed = UserStore.isAuthed();
		this.setState({ isAuthed });
	};

	handleChange = event => {
		const key = event.target.id;
		const value = event.target.value;
		this.setState({ [key]: value });
	};

	showLoading = () => {
		this.setState({
			isLoading: true
		});
	};

	dismiss = () => {
		this.setState({ isOpen: false });
	};

	emailAuth = event => {
		event.preventDefault();
		const { email, password, name } = this.state;
		if (email.length < 4) {
			alert('Please enter an email address.');
			return;
		}

		if (password.length < 4) {
			alert('Please enter a password.');
			return;
		}
		if (name.length < 3) {
			alert('Please enter your Name.');
			return;
		}
		this.setState({ requestLoading: true });
		const firstName = name || getUserProfileName(email);
		firebase
			.auth()
			.createUserWithEmailAndPassword(email, password)
			.then(credential => {
				this.showLoading();
				ReactPixel.fbq('track', 'CompleteRegistrationEmail');
				Dispatcher.dispatch({
					actionType: ActionTypes.CREATE_USER,
					data: {
						email: email,
						uid: credential.user.uid,
						firstName,
						isAmbassador: false
					}
				});
			})
			.catch(function(error) {
				let errorCode = error.code;
				let errorMessage = error.message;
				if (errorCode === 'auth/weak-password') {
					alert('The password is too weak.');
				} else {
					alert(errorMessage);
				}
				console.warn(error);
			})
			.finally(() => {
				this.setState({ requestLoading: false });
			});
	};

	googleAuth = () => {
		const provider = new firebase.auth.GoogleAuthProvider();
		firebase
			.auth()
			.signInWithPopup(provider)
			.then(result => {
				this.showLoading();
				const user = result.user;
				const firstName = result.user.displayName;
				ReactPixel.fbq('track', 'CompleteRegistrationGoogle');
				if (result.additionalUserInfo.isNewUser) {
					Dispatcher.dispatch({
						actionType: ActionTypes.CREATE_USER,
						data: {
							email: user.email,
							uid: user.uid,
							firstName,
							isAmbassador: false
						}
					});
				}
			})
			.catch(this.props.onError);
	};

	fbAuth = () => {
		const provider = new firebase.auth.FacebookAuthProvider();
		firebase
			.auth()
			.signInWithPopup(provider)
			.then(result => {
				this.showLoading();
				const user = result.user;
				const firstName =
					user.displayName || getUserProfileName(user.email);
				ReactPixel.fbq('track', 'CompleteRegistrationFacebook');
				if (result.additionalUserInfo.isNewUser) {
					Dispatcher.dispatch({
						actionType: ActionTypes.CREATE_USER,
						data: {
							email: user.email,
							uid: user.uid,
							firstName,
							isAmbassador: false
						}
					});
				}
			})
			.catch(error => {
				this.setState({
					isOpen: true,
					errorData: error
				});
			});
	};

	render() {
		const { isLoading, requestLoading, errorData, isOpen } = this.state;

		if (isLoading) {
			return <Loading></Loading>;
		}

		return (
			<div className="auth-container">
				<Alert
					content={errorData.message}
					dismiss={() => this.dismiss}
					isOpen={isOpen}
				/>
				<h2 className="title">Sign Up</h2>
				<div className="auth-form">
					<button className="button-auth" onClick={this.googleAuth}>
						<img alt={'google-icon'} src={google}></img>
						Sign Up with Google
					</button>
					<button className="button-auth" onClick={this.fbAuth}>
						<img alt={'facebook-icon'} src={facebook}></img>
						Sign Up with Facebook
					</button>
					<div className="auth-divider">
						<span className="auth-divider-line">
							<hr></hr>
						</span>
						<span className="auth-divider-o">O</span>
						<span className="auth-divider-line">
							<hr></hr>
						</span>
					</div>
					<form className="auth-fields" onSubmit={this.emailAuth}>
						<InputGroup>
							<InputGroupAddon addonType="prepend">
								<i className="fas fa-envelope"></i>
							</InputGroupAddon>
							<Input
								id="name"
								className="form-input"
								placeholder="name"
								onChange={this.handleChange}
							></Input>
						</InputGroup>
						<InputGroup style={{ marginTop: 15 }}>
							<InputGroupAddon addonType="prepend">
								<i className="fas fa-envelope"></i>
							</InputGroupAddon>
							<Input
								id="email"
								className="form-input"
								placeholder="Email"
								onChange={this.handleChange}
							></Input>
						</InputGroup>
						<InputGroup style={{ marginTop: 15, marginBottom: 30 }}>
							<InputGroupAddon addonType="prepend">
								<i className="fas fa-lock"></i>
							</InputGroupAddon>
							<Input
								id="password"
								className="form-input"
								type="password"
								placeholder="Password"
								onChange={this.handleChange}
							></Input>
						</InputGroup>
						<BtnLoader loading={requestLoading} text={'Sign Up'} />
					</form>
				</div>
				<div className="font-regular">
					<div>Already have an account?</div>
					<div
						className="link-primary"
						onClick={() => {
							this.props.history.push('/login');
						}}
					>
						<u>Sign In</u> 👈
					</div>
				</div>
			</div>
		);
	}
}

export default Signup;
