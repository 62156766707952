import React from 'react';
import { isNil } from 'lodash';

export default function GroupSizeDisplay({ numAdults, numChildren }) {
	if (isNil(numAdults) || isNil(numChildren)) {
		return null;
	}

	return (
		<>
			{numChildren === 0 ? (
				<div
					style={{ display: 'flex', justifyContent: 'space-around' }}
				>
					<div>
						<label>
							<b>{numAdults === 1 ? ' adult' : ' adults'}</b>
						</label>
						<div>
							<span>{numAdults}</span>
						</div>
					</div>
				</div>
			) : (
				<div
					style={{ display: 'flex', justifyContent: 'space-around' }}
				>
					<div>
						<label>
							<b>{numAdults === 1 ? ' adult' : ' adults'}</b>
						</label>
						<div>
							<span>{numAdults}</span>
						</div>
					</div>
					<div>
						<label>
							<b> {numChildren === 1 ? ' child' : ' children'}</b>
						</label>
						<div>
							<span>{numChildren}</span>
						</div>
					</div>
				</div>
			)}
		</>
	);
}
