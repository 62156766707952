import {
	faSwimmer,
	faHiking,
	faLeaf,
	faCocktail,
	faCamera,
	faUtensils,
	faAppleAlt,
	faDumbbell,
	faTags,
	faArchway,
	faFootballBall,
	faDove,
	faMosque,
	faChurch,
	faPalette,
	faUsers
} from '@fortawesome/free-solid-svg-icons';

export const categoryIcons = {
	Ei95oyO6kPQntGX0qRnO: faSwimmer,
	JQrfgqO4ZEW4mmgMQBEg: faHiking,
	DQoQMSAjltFfjB1p3uqN: faLeaf,
	t0yBSawiUs5cumRvZ8Nz: faDumbbell,
	D5eeFRpD7fx9s8Y4v49x: faCocktail,
	HiPMLgADzGjV7i4oFmmu: faUtensils,
	DpKQ3Ldh7DymDGidQXlu: faCamera,
	Gw7ktFK4PzCT6mcsRDV4: faAppleAlt,
	QHiLPQzOHNue9ixM6fXB: faTags,
	KB67tdt57U2QQrSCSrcu: faArchway,
	P3ghJW1Za8G29kvc87py: faFootballBall,
	QcbZbhVyxc3vXUS6oHOd: faDove,
	vaHYsgHjVvO0zAcXrzmi: faMosque,
	MPFfI1RKzee8r0tp8FUf: faChurch,
	MlLpwYavOtrk226VX2px: faPalette,
	f0lL8XvpmU5z0H70z7sx: faUsers,

	'Surfing and Other Water Sports': faSwimmer,
	'Hiking and Walks': faHiking,
	'Wildlife and Nature': faLeaf,
	'Bars and Nightlife': faCocktail,
	Photography: faCamera,
	'Food and Cooking': faUtensils,
	'Local Artisans and Markets': faAppleAlt,
	'Wellness and Exercise': faDumbbell,
	'Shopping and Fashion': faTags,
	'Unique & Unusual Places': faArchway,
	'Sporting Events': faFootballBall,
	'Ethical Travel and Volunteering': faDove,
	'Major Sites, Architecture and Orientation': faMosque,
	'Historical and Religious Sites': faChurch,
	'Art, Culture and Museums': faPalette,
	'Family and kids activities': faUsers
};
