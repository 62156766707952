import React, { Component } from 'react';
import './googleTranslate.scss';

export default class GoogleTranslate extends Component {
	componentDidMount() {
		const script = document.createElement('script');

		script.src =
			'//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
		script.async = true;

		document.body.appendChild(script);
	}
	render() {
		return <div id="google_translate_element">Translate:</div>;
	}
}
