import moment from 'moment';
import { difference, isEmpty } from 'lodash';

import {
	AVAILABILITY_EMAIL_FORMAT,
	DOB_DISPLAY_FORMAT
} from '../consts/DateFormats';

function picturesToHtml(current, changes) {
	const added = difference(changes, current);
	const removed = difference(current, changes);
	let html = '<ul>';

	if (!isEmpty(added)) {
		html += `<li><b>added</b> ${added.length} picture(s)</li>`;
		html += '<ol>';
		added.forEach(pic => {
			html += `<li><a href="${pic.url}">${pic.url}</a></li>`;
		});
		html += '</ol>';
	}

	if (!isEmpty(removed)) {
		html += `<li><b>removed</b> ${removed.length} picture(s)</li>`;
	}

	if (isEmpty(added) && isEmpty(removed)) {
		html += '<li>reordered</li>';
	}

	html += '</ul>';
	html += `<br>current pictures: ${changes.length}`;
	return html;
}

function availabilityToHtml(current, changes) {
	const currentDates = Object.keys(current);
	const changedDates = Object.keys(changes);

	const added = difference(changedDates, currentDates);
	const removed = difference(currentDates, changedDates);

	let html = '<ul>';
	let fDate;

	if (!isEmpty(added)) {
		html += `<li><b>added</b> ${added.length} date(s)</li>`;
		html += '<ol>';
		added.forEach(date => {
			fDate = moment(date).format(AVAILABILITY_EMAIL_FORMAT);
			html += `<li><b>${fDate}</b></li>`;
		});
		html += '</ol>';
	}

	if (!isEmpty(removed)) {
		html += `<li><b>removed</b> ${removed.length} date(s)</li>`;
		html += '<ol>';
		removed.forEach(date => {
			fDate = moment(date).format(AVAILABILITY_EMAIL_FORMAT);
			html += `<li><b>${fDate}</b></li>`;
		});
		html += '</ol>';
	}

	html += '</ul>';
	html += `<br>current available dates: ${changedDates.length}`;
	return html;
}

function exampleDayToHtml(current, changes) {
	const times = Object.keys(changes);
	let html = '<ul>';

	times.forEach(time => {
		html += `<li>${time}: ${changes[time]}</li>`;
	});

	html += '</ul>';
	return html;
}

export function profileChangesToHtml(user, changes) {
	const fields = Object.keys(changes);

	let changesHtml = '<ol>';
	let current;
	let change;

	fields.forEach(field => {
		current = user[field];
		change = changes[field];

		if (current !== change) {
			changesHtml += `<li>${field}`;

			if (field === 'dob') {
				current = moment(current).format(DOB_DISPLAY_FORMAT);
				change = moment(change).format(DOB_DISPLAY_FORMAT);
			}

			switch (field) {
				case 'lifestylePics':
					changesHtml += picturesToHtml(current, change);
					break;
				case 'availability':
					changesHtml += availabilityToHtml(current, change);
					break;
				case 'exampleDay':
					changesHtml += exampleDayToHtml(current, change);
					break;
				default:
					changesHtml += `<br>from: <b>${current}</b><br>to: <b>${change}</b>`;
					break;
			}

			changesHtml += '</li><br>';
		}
	});

	changesHtml += '</ol>';
	return changesHtml;
}
