import ElSalvador from '../assets/WizardForm/ElSalvador.jpg';
import Mexico from '../assets/WizardForm/Mexico.jpg';
import Guatemala from '../assets/WizardForm/Guatemala.jpg';
import CostaRica from '../assets/WizardForm/CostaRica.jpg';
import Guanacaste from '../assets/WizardForm/city/Guanacaste.jpg';
import SanJose from '../assets/WizardForm/city/Sanjose.jpg';
import MexicoCity from '../assets/WizardForm/city/MexicoCity.jpg';
import LaLibertad from '../assets/WizardForm/city/lalibertad.jpg';
import SanSalvador from '../assets/WizardForm/city/sanSalvador.jpg';
import GuatemalaCity from '../assets/WizardForm/city/GuatemalaCity.jpg';

export const Countries = [
	{
		id: '1',
		country: 'México',
		value: 'México',
		img: Mexico
	},
	{
		id: '2',
		country: 'El Salvador',
		value: 'El Salvador',
		img: ElSalvador
	},
	{
		id: '3',
		country: 'Guatemala',
		value: 'Guatemala',
		img: Guatemala
	},
	{
		id: '4',
		country: 'Costa Rica',
		value: 'Costa Rica',
		img: CostaRica
	}
];

export const Cities = {
	'El Salvador': [
		{ value: 'San Salvador', img: SanSalvador },
		{ value: 'La Libertad', img: LaLibertad }
	],
	México: [{ value: 'Mexico City', img: MexicoCity }],
	Guatemala: [{ value: 'Guatemala City', img: GuatemalaCity }],
	'Costa Rica': [
		{ value: 'San José', img: SanJose },
		{ value: 'Guanacaste', img: Guanacaste }
	]
};
