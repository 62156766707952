import React, { Component } from 'react';
import { isEmpty } from 'lodash';

import Dispatcher from '../../common/dispatcher/index';
import EventTypes from '../../common/events';
import ActionTypes from '../../common/actions';
import UserStore from '../../common/stores/user';

import Pagination from '../pagination/Pagination';
import ResultCard from './ResultCard';

import './searchResultList.scss';

export default class SearchResultList extends Component {
	constructor(props) {
		super();
		const numResultsPerPage = 3;
		const allInterests = UserStore.getInterests();

		this.state = {
			currentPage: 1,
			numResultsPerPage,
			first: 0,
			last: numResultsPerPage,
			allInterests
		};

		if (isEmpty(allInterests)) {
			Dispatcher.dispatch({
				actionType: ActionTypes.GET_INTERESTS
			});
		}
	}

	componentDidMount() {
		UserStore.addListener(
			EventTypes.INTERESTS_LOADED,
			this.onInterestsLoaded
		);
	}

	componentWillUnmount() {
		UserStore.removeListener(
			EventTypes.INTERESTS_LOADED,
			this.onInterestsLoaded
		);
	}

	onInterestsLoaded = () => {
		const allInterests = UserStore.getInterests();
		this.setState({
			allInterests
		});
	};

	handleChange = value => {
		const { first, last, currentPage } = value;
		this.setState({ first, last, currentPage });
	};

	render() {
		const {
			first,
			last,
			currentPage,
			numResultsPerPage,
			allInterests
		} = this.state;

		const {
			history,
			queryResult,
			dates,
			adults,
			kids,
			conversion,
			interests
		} = this.props;

		const filter = queryResult.slice(first, last);

		return (
			<>
				{!isEmpty(queryResult) ? (
					<div id="result-grid">
						<h4>
							Explore with one of our {queryResult.length}{' '}
							Ambassadors
						</h4>
						{filter.map((res, index) => {
							return (
								<ResultCard
									adults={adults}
									kids={kids}
									conversion={conversion}
									result={res}
									dates={dates}
									allInterests={allInterests}
									interests={interests}
									currentPage={currentPage}
									index={index}
									history={history}
								></ResultCard>
							);
						})}
						<Pagination
							length={queryResult.length}
							numberPerPage={numResultsPerPage}
							handleChange={e => this.handleChange(e)}
						/>
					</div>
				) : (
					// TODO: abstract to NoResultsActions component
					<p style={{ marginTop: 15 }}>
						We are sorry, there are no available RedKnot Ambassadors
						for your selected search parameters. <br></br>Click on
						the button below to modify them and search again or{' '}
						<a
							href="javascript:void(Tawk_API.toggle())"
							style={{
								color: 'red',
								fontWeight: 'bold',
								textDecoration: 'underline',
								fontSize: 16
							}}
						>
							Click Here
						</a>{' '}
						and chat with us to help you customize your trip.
					</p>
				)}
				<div className="btn-container">
					<button
						className="btn-primary inactive"
						onClick={() => {
							history.push({
								pathname: '/new',
								state: {
									step: 5
								}
							});
						}}
					>
						Previous
					</button>
					<button
						className="btn-primary"
						onClick={() => {
							history.push({
								pathname: '/new',
								state: {
									step: 1
								}
							});
						}}
					>
						Try Again
					</button>
				</div>
			</>
		);
	}
}
