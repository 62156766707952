import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

import './modalImg.scss';

const CustomModal = ({ toggle, isOpen, body, title }) => {
	const isUrl = url => {
		return Boolean(url.match(/^https?:\/\//));
	};

	const drawData = body => {
		if (body && isUrl(body)) {
			return <img alt={''} src={body} style={{ width: '100%' }}></img>;
		} else if (typeof body === 'object' && body) {
			return (
				<ul>
					{body.map(value => {
						return (
							<li>
								{value.shortDescription}
								<p>{value.LongDescription}</p>
							</li>
						);
					})}
				</ul>
			);
		} else {
			return <p>{body}</p>;
		}
	};

	return (
		<div>
			<Modal
				isOpen={isOpen}
				toggle={toggle}
				modalClassName={isUrl(body || '') && 'custom-modal'}
			>
				<ModalHeader toggle={toggle}>{title}</ModalHeader>
				<ModalBody>{drawData(body)}</ModalBody>
			</Modal>
		</div>
	);
};

export default CustomModal;
