import React, { Component } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { isEmpty } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faAngleLeft,
	faAngleRight,
	faTimes
} from '@fortawesome/free-solid-svg-icons';

import '../utils/modals.scss';

class GalleryModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			modal: props.isOpen,
			gallery: props.gallery,
			active: this.props.selected,
			toggle: props.toggle
		};
	}

	componentDidMount = () => {
		document.addEventListener('keydown', e => {
			switch (e.keyCode) {
				case 37:
					this.prevPhoto();
					break;
				case 39:
					this.nextPhoto();
					break;
				default:
					break;
			}
		});
	};

	componentDidUpdate(previousProps) {
		if (previousProps.selected !== this.props.selected) {
			this.setState({
				active: this.props.selected
			});
		}
	}

	selectPhoto = active => {
		this.setState({
			active
		});
	};

	reset = () => {
		this.setState({
			active: 0
		});
	};

	nextPhoto = () => {
		const active = this.state.active + 1;
		if (active > this.props.gallery.length - 1) {
			return this.setState({
				active: 0
			});
		}
		this.setState({
			active
		});
	};

	prevPhoto = () => {
		const active = this.state.active - 1;
		if (active < 0) {
			return this.setState({
				active: this.props.gallery.length - 1
			});
		}
		this.setState({
			active
		});
	};

	changeSelectedPhoto = e => {
		if (e.currentTarget.id === 'next-photo') {
			this.nextPhoto();
		} else if (e.currentTarget.id === 'prev-photo') {
			this.prevPhoto();
		}
	};

	render() {
		const { isOpen, gallery } = this.props;
		const { active } = this.state;

		if (isEmpty(gallery)) return null;

		return (
			<Modal
				id="gallery-modal"
				isOpen={isOpen}
				toggle={this.props.toggle}
				onClosed={this.reset}
			>
				<ModalBody>
					{/* hack to handle selected photo coming from parent and active photo selected from thumbnail */}
					<div
						className="selected-photo"
						style={{
							backgroundImage: 'url(' + gallery[active].url + ')'
						}}
					>
						<div className="control-container">
							<span className="close">
								<FontAwesomeIcon
									onClick={this.props.toggle}
									icon={faTimes}
								/>
							</span>
							<span>
								<FontAwesomeIcon
									onClick={this.changeSelectedPhoto}
									icon={faAngleLeft}
									id="prev-photo"
								/>
							</span>
							<span>
								<FontAwesomeIcon
									onClick={this.changeSelectedPhoto}
									icon={faAngleRight}
									id="next-photo"
								/>
							</span>
						</div>
					</div>
					<div className="thumbnail-container">
						{gallery.map((photo, idx) => {
							return (
								<div
									key={idx}
									className="gallery-photo"
									style={{
										backgroundImage:
											'url(' + photo.url + ')'
									}}
									onClick={() => this.selectPhoto(idx)}
								></div>
							);
						})}
					</div>
				</ModalBody>
			</Modal>
		);
	}
}

export default GalleryModal;
