import { PrivateRoute, PublicRoute } from './routeTypes';

import Profile from './components/profile/Profile';
import ProfileEdit from './components/profile/ProfileEdit';
import Login from './components/auth/Login';
import Signup from './components/auth/Signup';
import NewTrip from './components/newTrip/NewTrip';
import NewTripResultList from './components/newTrip/NewTripResults';
import User from './components/users/User';
import Booking from './components/bookings/Booking';
import Itinerary from './components/trips/Itinerary';
import CheckoutForm from './components/bookings/CheckoutForm';
import Trips from './components/trips/Trips';
import Chat from './components/chats/Chat';
import Review from './components/reviews/Review';
import NotFound from './components/utils/NotFound';

export default [
	{
		path: '/login',
		type: PublicRoute,
		component: Login
	},
	{
		path: '/signup',
		type: PublicRoute,
		component: Signup
	},
	{
		path: '/user/:uid',
		type: PublicRoute,
		component: User,
		title: ''
	},
	{
		path: '/new',
		exact: true,
		type: PublicRoute,
		component: NewTrip,
		title: 'New Trip'
	},
	{
		path: '/new/results',
		type: PublicRoute,
		component: NewTripResultList,
		title: 'These are the RedKnot Ambassadors picked for you:'
	},
	{
		path: '/editProfile',
		type: PrivateRoute,
		component: ProfileEdit,
		title: 'Edit Profile'
	},
	{
		path: '/profile',
		type: PrivateRoute,
		component: Profile,
		title: 'Your Profile'
	},
	{
		path: '/booking/:id',
		type: PrivateRoute,
		component: Booking,
		title: 'Trip Summary'
	},
	{
		path: '/trips',
		type: PrivateRoute,
		exact: true,
		component: Trips,
		title: 'My Trips'
	},
	{
		path: '/trips/:id',
		type: PrivateRoute,
		component: Itinerary
	},
	{
		path: '/checkout/:id',
		type: PrivateRoute,
		component: CheckoutForm
	},
	{
		path: '/chat/:id',
		type: PrivateRoute,
		component: Chat
	},
	{
		path: '/reviews/:id',
		type: PrivateRoute,
		component: Review
	},
	{
		path: '/',
		exact: true,
		type: PublicRoute,
		component: () => {
			window.location.href = 'https://goredknot.com';
			return null;
		}
	},
	{
		type: PublicRoute,
		component: NotFound
	}
];
