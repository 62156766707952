import React from 'react';
import { Button } from 'reactstrap';
import { isEmpty } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Loading } from '../utils/Loading';
import { categoryIcons } from '../../consts/Icons';

import './interests.scss';

export default function Interest({ loading, interests, onInterestClick }) {
	if (isEmpty(interests) || loading) {
		return <Loading></Loading>;
	}

	let interestKeys = Object.keys(interests);

	const sortedKeys = interestKeys.sort((a, b) => {
		return interests[a].order - interests[b].order;
	});

	return (
		<>
			<div className="user-input">
				<div id="interests-grid">
					{sortedKeys.map(key => {
						const interest = interests[key];
						// hide the following:
						if (
							interest.name === 'Activities for solo travelers' ||
							interest.name === 'Cooking' ||
							interest.name === 'Restaurants And Local Food' ||
							interest.name === 'Major sites / orientation' ||
							interest.name === 'Architecture' ||
							interest.name ===
								'History and historical landmarks' ||
							interest.name === 'Religious sites' ||
							interest.name === 'Galleries and museums' ||
							interest.name === 'Cultural sites'
						)
							return null;

						return (
							<Button
								key={key}
								className={
									interest.selected
										? 'interest-box selected'
										: 'interest-box'
								}
								onClick={e => onInterestClick(e)}
								value={key}
							>
								<div value={key}>
									<FontAwesomeIcon
										value={key}
										icon={categoryIcons[interest.name]}
										size="3x"
									/>
								</div>
								{interest.name}
							</Button>
						);
					})}
				</div>
			</div>
		</>
	);
}
