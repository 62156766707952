export default {
	CREATE_USER: 'CreateUser',
	USER_AUTHENTICATED: 'UserAuthenticated',
	UPDATE_USER: 'UpdateUser',
	FILTER_USERS: 'FilterUsers',
	GET_USER_PROFILE: 'GetUserProfile',
	GET_BOOKING: 'GetBooking',
	GET_ITINERARY: 'GetItinerary',
	CREATE_BOOKING: 'CreateBooking',
	CREATE_ITINERARY: 'CreateItinerary',
	SAVE_ITINERARY: 'SaveItinerary',
	UPDATE_BOOKING: 'UpdateBooking',
	UPDATE_AVAILABILITY: 'UpdateAvailability',
	GET_ITINERARIES: 'GetItineraries',
	GET_CHAT: 'GetChat',
	CREATE_MESSAGE: 'CreateMessage',
	GET_REVIEW: 'GetReview',
	UPDATE_REVIEW: 'UpdateReview',
	GET_REVIEWS: 'GetReviews',
	GET_INTERESTS: 'GetInterests',
	CREATE_CHECKOUT_SESSION: 'CreateCheckoutSession',

	// GRANULAR ACTIONS
	ADD_USER_TO_BOOKING: 'AddUserToBooking'
};
