import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { Container } from 'reactstrap';
import { Elements } from 'react-stripe-elements';

import GoogleTranslate from './components/utils/GoogleTranslate';
import { Loading } from './components/utils/Loading';
import Login from './components/auth/Login';
import Navigation from './components/layout/Navigation';

import brand_white from './assets/brand_white.png';
import WizardBackground from './assets/WizardForm/wizard-background.jpg';

const PublicRoute = ({
	component: Component,
	isAuthed,
	isAmbassador,
	title,
	...rest
}) => {
	return (
		<Route
			{...rest}
			render={props => {
				if (
					isAuthed &&
					(rest.path === '/login' || rest.path === '/signup')
				) {
					// Sign up -> booking redirect HACK
					// when router comes with bookingId set, redirect the user to its booking after auth
					if (props.history.location.state) {
						const bookingId =
							props.history.location.state.bookingId;
						if (bookingId) {
							const url = '/booking/' + bookingId;
							return <Redirect to={url} />;
						}
					}
					return <Redirect to="/profile" />;
				}
				if (rest.path === '/login' || rest.path === '/signup') {
					return (
						<div>
							{/* TODO: abstract to brand header */}
							<div className="brand-header">
								<img
									alt={'brand-header'}
									src={brand_white}
								></img>
							</div>
							<Component {...props} />
						</div>
					);
				}
				if (isAmbassador && !rest.path.includes('/user')) {
					props.history.push('/profile');
				} else {
					return (
						<div>
							<Navigation {...props}></Navigation>
							{/* new trip background image */}
							{rest.path === '/new' ? (
								<Container
									style={{
										backgroundImage:
											'url(' + WizardBackground + ')'
									}}
									id="new-path-container"
								>
									<div className="page-title">{title}</div>
									<GoogleTranslate />
									<Component {...props} />
								</Container>
							) : (
								<Container>
									<div className="page-title">{title}</div>
									<GoogleTranslate />
									<Component {...props} />
								</Container>
							)}
						</div>
					);
				}
			}}
		/>
	);
};

const PrivateRoute = ({
	component: Component,
	isAuthed,
	isUserLoaded,
	title,
	isAmbassador,
	...rest
}) => {
	return (
		<Route
			{...rest}
			render={props => {
				if (!isUserLoaded) {
					return (
						<Container>
							<Loading />
						</Container>
					);
				} else if (!isAuthed) {
					return (
						<div>
							{/* TODO: abstract to brand header */}
							<div className="brand-header">
								<img
									alt={'brand-header'}
									src={brand_white}
								></img>
							</div>
							<Login {...props} />
						</div>
					);
				} else if (isAmbassador && rest.path.includes('/booking')) {
					props.history.push('/profile');
				} else {
					return (
						<div>
							<Navigation {...props}></Navigation>
							<Container>
								<GoogleTranslate />
								<div className="page-title">{title}</div>

								{rest.path === '/checkout/:id' ? (
									<Elements>
										<Component {...props} />
									</Elements>
								) : (
									<Component {...props} />
								)}
							</Container>
						</div>
					);
				}
			}}
		/>
	);
};

export { PublicRoute, PrivateRoute };
