import React, { Component } from 'react';
import { mapValues, isEmpty } from 'lodash';

import Dispatcher from '../../common/dispatcher/index';
import EventTypes from '../../common/events';
import ActionTypes from '../../common/actions';
import UserStore from '../../common/stores/user';

import InterestsBoxes from './InterestsBoxes';

import './step5.scss';

export default class Step5 extends Component {
	constructor(props) {
		super();
		const interests = UserStore.getInterests();

		this.state = {
			loading: false,
			interests
		};

		if (isEmpty(interests)) {
			Dispatcher.dispatch({
				actionType: ActionTypes.GET_INTERESTS
			});
		}
	}

	componentDidMount() {
		UserStore.addListener(
			EventTypes.INTERESTS_LOADED,
			this.onInterestsLoaded
		);
	}

	componentWillUnmount() {
		UserStore.removeListener(
			EventTypes.INTERESTS_LOADED,
			this.onInterestsLoaded
		);
	}

	onInterestsLoaded = () => {
		const allInterests = UserStore.getInterests();
		const interests = mapValues(allInterests, interest => {
			interest['selected'] = false;
			return interest;
		});

		this.setState({
			interests
		});
	};

	handleInterestClick = event => {
		const id = event.currentTarget.value;
		const { interests } = this.state;

		interests[id].selected = !interests[id].selected;
		this.props.handleChange({
			name: 'interests',
			value: interests
		});
	};

	render() {
		const { loading, interests } = this.state;

		return (
			<div id="step-5" className="main-container">
				<div className="main-title-container">
					<div className="step-container">
						<div className="step">
							<span>4</span>
						</div>
					</div>
					<div className="title-container">
						<h2>Let us know more about your interests!</h2>
					</div>
				</div>
				<div className="options-container">
					<InterestsBoxes
						loading={loading}
						interests={interests}
						onInterestClick={this.handleInterestClick}
					/>
				</div>
			</div>
		);
	}
}
