import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';

import './pagination.scss';

export default class Pagination extends Component {
	constructor(props) {
		super(props);
		const { currentPage, numberPerPage } = this.props;
		this.state = {
			currentPage: currentPage || 1,
			numberPerPage,
			totalPages: Math.ceil(this.props.length / numberPerPage)
		};
	}

	loadPage = page => {
		const { numberPerPage } = this.state;
		const newLast = numberPerPage * page;
		const newFirst = numberPerPage * page - numberPerPage;

		this.setState({
			currentPage: parseInt(page)
		});

		this.props.handleChange({
			currentPage: parseInt(page),
			first: newFirst,
			last: newLast
		});
	};

	loadNextPage = () => {
		const { currentPage, totalPages } = this.state;
		const next = currentPage + 1;

		if (next <= totalPages) {
			this.loadPage(next);
		}
	};

	loadPrevPage = () => {
		const { currentPage } = this.state;
		const prev = currentPage - 1;

		if (prev >= 1) {
			this.loadPage(prev);
		}
	};

	createPagination = () => {
		const { totalPages, currentPage } = this.state;
		let numbers = [];

		for (let i = 1; i <= totalPages; i++) {
			numbers.push(
				<div
					className={
						currentPage === i
							? 'pagination-number'
							: 'pagination-number active'
					}
					onClick={e => this.loadPage(i)}
				>
					<span value={i}>{i}</span>
				</div>
			);
		}

		return numbers;
	};

	render() {
		return (
			<div className="pagination">
				<span className="arrow-left">
					<FontAwesomeIcon
						onClick={this.loadPrevPage}
						icon={faAngleLeft}
					/>
				</span>
				<div className="pagination-numbers-container">
					{this.createPagination()}
				</div>
				<span className="arrow-right">
					<FontAwesomeIcon
						onClick={this.loadNextPage}
						icon={faAngleRight}
					/>
				</span>
			</div>
		);
	}
}
