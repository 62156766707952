class Mailer {
	static sendUserSignupMessage(userEmail) {
		window.emailjs
			.send('bridges', 'user_signup_message', {
				to_email: userEmail
			})
			.then(res => {
				console.log('Email successfully sent!');
			})
			.catch(err =>
				console.warn('error sending user signup message', err)
			);
	}

	static sendTravelerPurchaseConfirmation(
		userName,
		userEmail,
		ambassadorName,
		city,
		dates
	) {
		// console.log('Sending ambassador traveler purchase confirmation email');
		window.emailjs
			.send('bridges', 'traveler_booking_acknowledge', {
				to_email: userEmail,
				to_name: userName,
				rka_name: ambassadorName,
				city,
				dates
			})
			.then(res => {
				console.log('Email successfully sent!');
			})
			.catch(err =>
				console.warn(
					'error sending on traveler purchase acknowledge email',
					err
				)
			);
	}

	static sendAmbassadorPurchaseConfirmation(
		userName,
		userEmail,
		travelerName,
		city,
		itineraryId,
		dates
	) {
		// console.log('Sending ambassador purchase acknowledge email');
		// TODO: generate and handle confirmation link
		// const confirmLink = 'analytics.google.com';
		window.emailjs
			.send('bridges', 'ambassador_booking_confirm', {
				to_email: userEmail,
				to_name: userName,
				trk_name: travelerName,
				city,
				dates,
				// confirm_link: '<a href="' + confirmLink + '">here</a>',
				itinerary_link:
					'<a href="app.goredknot.com/trips/' +
					itineraryId +
					'">here</a>'
			})
			.then(res => {
				console.log('Email successfully sent!');
			})
			.catch(err =>
				console.warn(
					'error sending on ambassador purchase confirm email',
					err
				)
			);
	}

	static sendChatNotification(userEmail, userName, message, flag, chatId) {
		if (flag) {
			window.emailjs
				.send('bridges', 'chat_alert', {
					to_email: userEmail,
					rka_name: userName,
					message,
					chat_link: `app.goredknot.com/chat/${chatId}`
				})
				.then(res => {
					console.log('Email successfully sent!');
				})
				.catch(err =>
					console.warn(
						'error sending chat message notification email',
						err
					)
				);
		}
	}

	static sendProfileUpdateNotification(userName, userId, changesHtml) {
		window.emailjs
			.send('bridges', 'ambassador_profile_update_notification', {
				rka_name: userName,
				profile_link: `app.goredknot.com/user/${userId}`,
				changes_html: changesHtml
			})
			.catch(err => {
				console.warn(
					'error sending ambassador profile notification email',
					err
				);
			});
	}
}

export default Mailer;
