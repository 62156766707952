import { gql } from 'apollo-boost';

export const GET_AVAILABLE_USERS = gql`
	query availabilityQuery(
		$filter: [String]
		$availability: [String]
		$interests: [String]
		$city: String
	) {
		availableUsers(
			CarType: $filter
			Dates: $availability
			Interests: $interests
			City: $city
		) {
			uid
			firstName
			bio
			carType
			photo
			city
			languages
			tagline
			rkVerified
			categoryScores
			tariff
		}
	}
`;

export const GET_ALL_USERS = gql`
	query allUsersQuery {
		allUsers {
			uid
			firstName
			email
			isAmbassador
		}
	}
`;

export const GET_ALL_TRIPS = gql`
	query allTripsQuery {
		allTrips {
			id
			chatId
			city
			days
			ambassadorId
			travelerId
			ambassadorName
		}
	}
`;
