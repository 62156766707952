import firebase from 'firebase';
import config from './config';

// firebase init
export const firebaseConfig = {
	apiKey: config.apiKey,
	projectId: config.projectId,
	authDomain: config.authDomain,
	storageBucket: config.storageBucket,
	backendUrl: config.backendUrl,
	databaseURL: 'https://redknot-staging.firebaseio.com'
};

export const firebaseApp = firebase.initializeApp(firebaseConfig);
