const PriceDetailCentralAmerica = [1, 10, 7, 33, 10];

const SpecialPrice = [1, 15, 20, 30, 15, 14];

const normalValorPerPerson = travelers => {
	switch (travelers) {
		case 1:
			return 0;

		case 2:
		case 3:
			return PriceDetailCentralAmerica[1];
		case 4:
			return PriceDetailCentralAmerica[2];
		case 5:
			return PriceDetailCentralAmerica[3];
		case 6:
			return PriceDetailCentralAmerica[4];
		default:
			return PriceDetailCentralAmerica[4];
	}
};

const specialValorPerPerson = travelers => {
	switch (travelers) {
		case 1:
			return 0;
		case 2:
		case 3:
			return SpecialPrice[1];
		case 4:
			return SpecialPrice[2];
		case 5:
			return SpecialPrice[3];
		case 6:
			return SpecialPrice[4];
		default:
			return SpecialPrice[5];
	}
};

export const priceRange = (country, numTravelers) => {
	if (
		country === 'El Salvador' ||
		country === 'Guatemala' ||
		country === 'México'
	) {
		return normalValorPerPerson(numTravelers);
	} else {
		return specialValorPerPerson(numTravelers);
	}
};
