import React, { Component } from 'react';
import { Progress } from 'reactstrap';
import { withApollo } from 'react-apollo';
import { isEmpty, pickBy } from 'lodash';

import UserStore from '../../common/stores/user';
import EventTypes from '../../common/events';

import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import Step5 from './Step5';
import Alert from '../utils/Alert';

import { priceRange } from '../utils/priceRange';
import { getUrlParameters } from '../../utils/Validation';
import { toMoment } from '../../utils/Dates';

import { GET_AVAILABLE_USERS } from '../../consts/Queries';

import './newTrip.scss';
import { AVAILABILITY_DB_FORMAT } from '../../consts/DateFormats';

class NewTrip extends Component {
	constructor(props) {
		super(props);

		const country = localStorage.getItem('country') || '';
		const city = localStorage.getItem('city');
		const option = localStorage.getItem('option') || '';
		const adults = parseInt(localStorage.getItem('adults')) || 0;
		const kids = parseInt(localStorage.getItem('kids')) || 0;
		const numTravelers =
			parseInt(localStorage.getItem('numTravelers')) || 0;
		// hack to remove old dates giving problems
		// const persistedDates = localStorage.getItem('dates') || [];
		const persistedDates = [];
		const openSearch =
			JSON.parse(localStorage.getItem('openSearch')) || false;

		// hack to read step from outside components
		const step = !isEmpty(props.location.state)
			? parseInt(props.location.state.step)
			: 1; // Default step is 1

		const interests = UserStore.getInterests();

		// wipe step from location
		if (props.location.state) {
			props.history.replace({ state: {} });
		}

		let datesArray = [];
		if (!isEmpty(persistedDates)) {
			datesArray = persistedDates.split(',');
		}

		let dates = this.hasOldDate(datesArray) || openSearch ? [] : datesArray;

		this.state = {
			step,
			city,
			country,
			option: option,
			adults,
			kids,
			numTravelers,
			dates,
			openSearch,
			error: false,
			errorMsg: 'Please fill in the fields to continue',
			conversion: 0,
			interests,
			requestLoading: false
		};
	}

	componentDidMount() {
		const { adults, city, country, kids, dates } = getUrlParameters();
		if (adults && city && country && kids) {
			this.setState({
				adults: Math.abs(parseInt(adults) || 1),
				city,
				country,
				kids: Math.abs(parseInt(kids) || 0),
				numTravelers:
					Math.abs(parseInt(adults) || 1) +
					Math.abs(parseInt(kids) || 0),
				step: 5,
				dates: dates ? dates.split(',') : [],
				openSearch: isEmpty(dates),
			});
		}

		UserStore.addListener(
			EventTypes.INTERESTS_LOADED,
			this.onInterestsLoaded
		);
	}

	onInterestsLoaded = () => {
		const interests = UserStore.getInterests();
		this.setState({
			interests
		});
	};

	handleChange = event => {
		const currentStep = this.state.step;
		const { name, value } = event;
		// quick hack to go next automatically on step 1 and 2
		const step =
			name === 'step'
				? value
				: currentStep === 1
				? 2
				: currentStep === 2
				? 3
				: currentStep;

		this.dismissAlert();
		this.calculatePrice();

		this.setState({
			[name]: value,
			step
		});

		localStorage.setItem(name, value);
	};

	hasOldDate = dates => {
		const today = new Date().setHours(0, 0, 0, 0);
		let dateObj;
		let dateTime;
		let slashDate;
		let isOldDate = false;

		dates.forEach(date => {
			slashDate = date.split('-').join('/');
			dateObj = new Date(slashDate);
			dateTime = dateObj.getTime();
			if (dateTime < today) {
				// wipe all dates from storage when old dates present
				localStorage.setItem('dates', []);
				isOldDate = true;
			}
		});
		return isOldDate;
	};

	nextStep = () => {
		if (this.isStepValid()) {
			let { step } = this.state;
			step = step >= 4 ? 5 : step + 1;
			this.handleChange({ name: 'step', value: step });
		} else {
			this.setState({
				error: true
			});
		}
	};

	prevStep = () => {
		let { step } = this.state;

		step = step <= 1 ? 1 : step - 1;
		this.handleChange({ name: 'step', value: step });
	};

	calculatePrice = () => {
		const { numTravelers, country } = this.state;
		let conversion = 0;
		var i;
		for (i = 1; i <= numTravelers; i++) {
			conversion = conversion + priceRange(country, i);
		}
		this.setState({ conversion });
	};

	// TODO: get validation error message
	isStepValid = () => {
		const {
			step,
			country,
			city,
			adults,
			kids,
			dates,
			openSearch
		} = this.state;

		switch (step) {
			case 1:
				if (isEmpty(country)) {
					return false;
				} else {
					return true;
				}

			case 2:
				if (isEmpty(city)) {
					return false;
				} else {
					return true;
				}

			case 3:
				if (parseInt(adults) <= 0 || parseInt(kids) < 0) {
					return false;
				} else {
					return true;
				}

			case 4:
				if (isEmpty(dates) && !openSearch) {
					return false;
				} else {
					return true;
				}

			default:
				return false;
		}
	};

	dismissAlert = () => {
		this.setState({ error: false });
	};

	handleSubmit = event => {
		event.preventDefault();
	};

	gqlSearch = async () => {
		let {
			city,
			dates,
			numTravelers,
			adults,
			kids,
			conversion,
			interests
		} = this.state;

		numTravelers = parseInt(adults) + parseInt(kids);
		let selectedInterests;
		let interestsFilter;
		let carType = [];
		let availability = [];
		let result;
		let queryResult;
		this.setState({ requestLoading: true });

		selectedInterests = pickBy(interests, interest => {
			return interest.selected;
		});

		interestsFilter = Object.keys(selectedInterests);

		if (0 < numTravelers && numTravelers < 5) carType.push('car');
		if (0 < numTravelers && numTravelers < 10) carType.push('suv');
		if (0 < numTravelers && numTravelers < 15) carType.push('van');

		availability = toMoment(dates, AVAILABILITY_DB_FORMAT);

		const filter = carType;
		result = await this.props.client.query({
			query: GET_AVAILABLE_USERS,
			variables: {
				filter,
				availability,
				interests: interestsFilter,
				city,
			},
		});
		queryResult = result.data.availableUsers;

		window.scrollTo(0, 0);
		this.setState({ requestLoading: false });
		this.props.history.push({
			pathname: '/new/results',
			state: {
				queryResult,
				dates,
				interests,
				adults,
				kids,
				conversion
			}
		});
	};

	get previousButton() {
		let { step } = this.state;

		if (step !== 1) {
			return (
				<button
					className="btn-primary inactive"
					type="button"
					onClick={this.prevStep}
				>
					Previous
				</button>
			);
		}

		return null;
	}

	get nextButton() {
		let { step } = this.state;
		const buttonActive = this.isStepValid();

		if (step < 5) {
			return (
				<button
					className={
						buttonActive ? 'btn-primary' : 'btn-primary inactive'
					}
					type="button"
					onClick={this.nextStep}
				>
					Next
				</button>
			);
		}

		return null;
	}

	get doneButton() {
		let { step } = this.state;

		if (step >= 5) {
			return (
				<button
					className="btn-primary"
					type="button"
					onClick={this.gqlSearch}
				>
					Connect Me!
				</button>
			);
		}

		return null;
	}

	render() {
		const {
			step,
			country,
			city,
			option,
			adults,
			kids,
			numTravelers,
			dates,
			openSearch,
			error,
			errorMsg,
			interests
		} = this.state;

		return (
			<div id="new-trip">
				<div className="progress-bar-container">
					<span className="progress-status">{`${
						step === 2 || step === 1 ? '1' : parseInt(step) - 1
					} / 4`}</span>
					<Progress color="danger" value={step * 20}></Progress>
				</div>
				<form onSubmit={this.handleSubmit}>
					{step === 1 && (
						<Step1
							country={country}
							handleChange={this.handleChange}
						/>
					)}
					{step === 2 && (
						<Step2
							handleChange={this.handleChange}
							country={country}
							city={city}
						/>
					)}
					{step === 3 && (
						<Step3
							handleChange={e => this.handleChange(e)}
							option={option}
							adults={adults}
							kids={kids}
							numTravelers={numTravelers}
						/>
					)}
					{step === 4 && (
						<Step4
							handleChange={e => this.handleChange(e)}
							dates={dates}
							openSearch={openSearch}
						/>
					)}
					{step === 5 && (
						<Step5
							step={step}
							handleChange={e => this.handleChange(e)}
						/>
					)}
					<div className="btn-container">
						{this.previousButton}
						{step !== 1 && step !== 2 && this.nextButton}
						{!isEmpty(interests) && this.doneButton}
						<Alert
							content={errorMsg}
							dismiss={() => this.dismissAlert}
							isOpen={error}
						/>
					</div>
				</form>
			</div>
		);
	}
}

export default withApollo(NewTrip);
