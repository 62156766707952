import moment from 'moment';

export function isValidDate(date) {
	return date instanceof Date ? !isNaN(date) : !isNaN(new Date(date));
}

export function toDate(dates) {
	let tmp;
	return dates.map(date => {
		tmp = new Date(date);
		// Timezone hack, otherwise the new date returns a day less
		tmp.setDate(tmp.getDate() + 1);
		return tmp;
	});
}

export function toMoment(dates, format, form) {
	function castToMoment(date, form) {
		return form
			? moment(date, form).format(format)
			: moment(date).format(format);
	}

	return dates.map(date => castToMoment(date, form));
}

function getFollowingDay(date) {
	const dateObj = new Date(date);
	const following = new Date(dateObj);
	following.setDate(dateObj.getDate() + 1);
	return following;
}

// Get a range of dates (array) to disable on datepicker
// start and end format is a recognizable string or Date
export function getDateRange(first, last) {
	const firstDate = new Date(first);
	const lastDate = new Date(last);

	let datesRange = [];
	let current = firstDate;

	while (current.getTime() <= lastDate.getTime()) {
		current = getFollowingDay(current);
		datesRange.push(current);
	}

	return datesRange;
}
