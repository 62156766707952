import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

export default function BtnLoader({ loading, text, mainFunction, icon }) {
	return (
		<button className="button-primary" disabled={loading}>
			{loading && (
				<FontAwesomeIcon
					icon={faSpinner}
					spin
					style={{ marginRight: '5px' }}
				/>
			)}
			{icon ? (
				<FontAwesomeIcon icon={icon} style={{ marginRight: '5px' }} />
			) : null}
			{loading ? <span>Loading...</span> : <span>{text}</span>}
		</button>
	);
}
