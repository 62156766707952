import React, { Component } from 'react';
import { isEmpty } from 'lodash';

import { Cities } from '../../consts/Countries';
import './step2.scss';

export default class Step2 extends Component {
	constructor(props) {
		super();
	}

	selectCity = city => {
		this.props.handleChange({
			name: 'city',
			value: city
		});
	};

	render() {
		const { country, city } = this.props;
		// when no country is selected go to step 1
		if (isEmpty(country)) {
			this.props.handleChange({
				name: 'step',
				value: 1
			});
			return null;
		}

		const cities = Cities[country];

		return (
			<div id="step-2" className="main-container">
				<div className="main-title-container">
					<div className="step-container">
						<div className="step">
							<span>1</span>
						</div>
					</div>
					<div className="title-container">
						<h2>Tell us where you are traveling</h2>
					</div>
				</div>
				<div className="city-container">
					{cities.map(item => {
						return (
							<div
								className={
									city === ''
										? 'city-box'
										: item.value === city
										? 'city-box active'
										: 'city-box inactive'
								}
								onClick={() => this.selectCity(item.value)}
								style={{
									backgroundImage: 'url(' + item.img + ')',
									height: 'calc(90% / ' + cities.length + ')'
								}}
							>
								<p>{item.value}</p>
							</div>
						);
					})}
				</div>
			</div>
		);
	}
}
