import React, { Component } from 'react';
import MultipleDatePicker from 'react-multiple-datepicker';
import { isEmpty } from 'lodash';

import AlertMsg from '../utils/Alert';
import { getDateRange, toDate, toMoment } from '../../utils/Dates';
import { BOOKING_DB_FORMAT } from '../../consts/DateFormats';

import './step4.scss';

export default class Step4 extends Component {
	constructor(props) {
		super();
		const persistedDates = props.dates;
		// COVID specific
		const apr1 = '2020-04-01';
		const oct31 = '2020-10-31';
		const disabledDates = getDateRange(apr1, oct31);

		this.state = {
			persistedDates,
			disabledDates
		};
	}

	convertTime = dates => {
		let dateObj;
		let dateTime;
		let slashDate;
		let formattedDates = [];
		const today = new Date().setHours(0, 0, 0, 0);
		dates.forEach(date => {
			slashDate = date.split('-').join('/');
			dateObj = new Date(slashDate);
			dateTime = dateObj.getTime();
			if (dateTime < today) {
				localStorage.clear();
			} else {
				formattedDates.push(dateObj);
			}
		});
		return formattedDates;
	};

	handleDatesInput(dates) {
		let formattedDates = toMoment(dates, BOOKING_DB_FORMAT);
		this.props.handleChange({
			name: 'dates',
			value: formattedDates
		});
	}

	toggleOpenSearch = () => {
		const { openSearch, dates } = this.props;
		const { persistedDates } = this.state;

		this.props.handleChange({
			name: 'openSearch',
			value: !openSearch
		});

		if (!openSearch) {
			this.setState({
				persistedDates: dates
			});
		}

		this.props.handleChange({
			name: 'dates',
			value: !openSearch ? [] : persistedDates
		});
	};

	render() {
		const { disabledDates } = this.state;
		const { openSearch, dates } = this.props;

		let formattedDates = [];
		if (!isEmpty(dates)) {
			formattedDates = toDate(dates);
		}

		return (
			<div id="step-4" className="main-container">
				<div className="main-title-container">
					<div className="step-container">
						<div className="step">
							<span>3</span>
						</div>
					</div>
					<div className="title-container">
						<h2>When would you like to travel with a RedKnot?</h2>
					</div>
				</div>
				<div className="options-container">
					<div
						className={
							openSearch
								? 'dates-container open-search'
								: 'dates-container'
						}
					>
						<MultipleDatePicker
							className={!isEmpty(dates) && 'filled'}
							minDate={new Date()}
							onSubmit={dates => {
								if (openSearch) {
									this.toggleOpenSearch();
								}
								this.handleDatesInput(dates);
							}}
							selectedDates={formattedDates}
							disabledDates={disabledDates}
						/>
						<AlertMsg
							content="To keep our community safe we have decided to suspend all RedKnot bookings until October."
							color="warning"
							isOpen={true}
						></AlertMsg>
						<div className="no-dates-checkbox">
							<button
								className={
									openSearch
										? 'btn-travel'
										: 'btn-travel inactive'
								}
								onClick={e => this.toggleOpenSearch()}
							>
								I’m not sure of my travel dates, just match me
								with a RedKnot Ambassador
							</button>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
