import moment from 'moment';
import { isEmpty } from 'lodash';

import { AVAILABILITY_DB_FORMAT } from '../consts/DateFormats';

export const calculateAge = date => {
	var years = moment().diff(date, 'years', false);
	return years;
};

export const isOfAge = date => {
	const years = calculateAge(date);
	if (years < 18) {
		alert('you must be 18 or higher');
	}
	return years > 18;
};

export const convertDate = inputFormat => {
	function pad(s) {
		return s < 10 ? '0' + s : s;
	}
	var d = new Date(inputFormat);
	return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join('-');
};

export const isFileExtValid = filePatch => {
	let file = filePatch;
	let extensions = /(\.jpg|\.jpeg|\.png)$/i;
	if (!extensions.exec(file)) {
		alert('File extension not supported!');
		return false;
	}
	return true;
};

export const areFieldsValid = inputFields => {
	const {
		availability,
		city,
		carType,
		dob,
		isAmbassador,
		lifestylePics,
		...fields
	} = inputFields;
	for (var index in fields) {
		if (isEmpty(inputFields[index])) {
			alert('Please fill in ' + index);
			return false;
		}
	}
	return true;
};

export const getUserProfileName = email => {
	return (email = email.match(/^([^@]*)@/)[1]);
};

export const cleanUrlToShare = url => {
	return (url = url.substring(0, url.indexOf('?')));
};

export const readMore = (text, size) => {
	return text.length > size ? `${text.substring(0, size)}...` : text;
};

export const getOrderedDates = days => {
	const keys = Object.keys(days);
	keys.sort((k1, k2) => {
		return new Date(k1).getTime() - new Date(k2).getTime();
	});
	return keys;
};

export const getNullDates = (startDate, endDate) => {
	const dates = [];
	let currentDate = new Date(
		startDate.getFullYear(),
		startDate.getMonth(),
		startDate.getDate()
	);

	while (currentDate <= endDate) {
		dates.push(currentDate);

		currentDate = new Date(
			currentDate.getFullYear(),
			currentDate.getMonth(),
			currentDate.getDate() + 1
		);
	}

	return dates;
};

export const getAvailability = availability => {
	const userDates = availability || [];
	const dateKeys = Object.keys(availability || []);
	let availableDates = [];
	let unavailableDates = [];
	let formattedDate;
	dateKeys.forEach(date => {
		// TODO: refactor
		formattedDate = new Date(
			moment(date, AVAILABILITY_DB_FORMAT).format('DD MMMM YYYY')
		);
		userDates[date]
			? availableDates.push(formattedDate.getTime())
			: unavailableDates.push(formattedDate);
	});

	const maxDate = new Date(Math.max.apply(null, availableDates));
	const minDate = new Date(Math.min.apply(null, availableDates));
	let nullDates = getNullDates(minDate, maxDate);

	unavailableDates = nullDates.filter(date => {
		return !availableDates.includes(date.getTime());
	});

	return { availableDates, unavailableDates };
};

export const getUrlParameters = () => {
	var urls = {};
	window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(
		m,
		key,
		value
	) {
		key = decodeURIComponent(key);
		value = decodeURIComponent(value);
		urls[key] = value;
	});
	return urls;
};
