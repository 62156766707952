import React, { Component } from 'react';
import SearchResultList from '../resultsList/SearchResultList';

export default class NewTripResults extends Component {
	constructor(props) {
		super();
	}

	render() {
		const { history } = this.props;

		if (!this.props.location.state) {
			history.push('/new');
			return null;
		}

		const {
			queryResult,
			dates,
			interests,
			adults,
			kids,
			conversion
		} = this.props.location.state;

		return (
			<>
				<SearchResultList
					queryResult={queryResult}
					dates={dates}
					interests={interests}
					adults={adults}
					kids={kids}
					conversion={conversion}
					history={history}
				/>
			</>
		);
	}
}
