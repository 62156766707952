import { useEffect } from 'react';
import ReactGA from 'react-ga';
import { withRouter } from 'react-router';

const sendPageView = location => {
	ReactGA.set({ page: location.pathname });
	ReactGA.pageview(location.pathname);
};

const AnalyticsListener = ({ children, trackingId, history }) => {
	useEffect(() => {
		ReactGA.initialize(trackingId);
		sendPageView(history.location);
		return history.listen(sendPageView);
	}, [history, trackingId]);

	return children;
};

export default withRouter(AnalyticsListener);
