import React, { Component } from 'react';
import firebase from 'firebase';
import {
	Collapse,
	Navbar,
	NavbarToggler,
	Nav,
	NavItem,
	NavLink
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';

import { Link } from 'react-router-dom';

import UserStore from '../../common/stores/user';
import EventTypes from '../../common/events';

import brand from '../../assets/brand-logo.png';

import './navigation.scss';

// TODO: find a better solution
const pageTitles = {
	'/new': 'New Trip',
	'/profile': 'Your Profile',
	'/editProfile': 'Edit Profile'
};

class Navigation extends Component {
	constructor(props) {
		super();
		const path = props.match.path;
		const title = pageTitles[path] || '';
		// const title = props.title || '';
		const user = UserStore.getUser();

		this.state = {
			title,
			isOpen: false,
			user,
			isAuthed: UserStore.isAuthed(),
			isAmbassador: UserStore.isAmbassador(),
			isAgent: UserStore.isAgent()
		};
	}

	toggle = () => {
		this.setState({
			isOpen: !this.state.isOpen
		});
	};

	closeToggle = () => {
		this.setState({
			isOpen: false
		});
	};
	componentDidMount() {
		UserStore.addListener(EventTypes.USER_LOADED, this.onUserLoaded);
	}

	componentWillUnmount() {
		UserStore.removeListener(EventTypes.USER_LOADED, this.onUserLoaded);
	}

	onUserLoaded = () => {
		this.setState({
			user: UserStore.getUser(),
			isAuthed: UserStore.isAuthed(),
			isAmbassador: UserStore.isAmbassador(),
			isAgent: UserStore.isAgent()
		});
	};

	logout = () => {
		localStorage.clear();
		firebase.auth().signOut();
		this.props.history.push('/login');
	};

	render() {
		const { user, isAuthed, isAmbassador, isAgent } = this.state;

		return (
			<div
				id="navbar"
				onBlur={() => this.closeToggle()}
				onFocus={() => this.closeToggle()}
			>
				<Navbar color="faded" light className={isAgent && 'agent'}>
					<div style={{ textAlign: 'left', fontSize: 26 }}>
						<FontAwesomeIcon
							size="lg"
							icon={faAngleLeft}
							onClick={() => {
								this.props.history.goBack();
							}}
							style={{ cursor: 'pointer' }}
						/>
					</div>
					<a
						target="_blank"
						rel="noopener noreferrer"
						href="https://goredknot.com"
					>
						<img id="nav-brand" src={brand} alt={''}></img>
					</a>
					<NavbarToggler
						onClick={this.toggle}
						className="mr-2"
						style={{ border: 'none', outline: 'none' }}
					/>
					<Collapse isOpen={this.state.isOpen} navbar>
						<Nav navbar>
							{/* TODO: implement private and public navbars */}
							{isAuthed ? (
								<div>
									<NavItem>
										<Link to="/profile/">
											<NavLink>Home</NavLink>
										</Link>
									</NavItem>
									{!isAmbassador &&
										(user.firstName ? (
											<NavItem>
												<Link to="/new/">
													<NavLink>New Trip</NavLink>
												</Link>
											</NavItem>
										) : (
											<NavItem>
												<Link
													onClick={e => {
														alert(
															'First tell us your name!'
														);
													}}
													to="/editProfile/"
												>
													<NavLink>New Trip</NavLink>
												</Link>
											</NavItem>
										))}
									<NavItem>
										<Link to="/profile/">
											<NavLink>My Profile</NavLink>
										</Link>
									</NavItem>
									<NavItem>
										<Link to="/trips/">
											<NavLink>My Trips</NavLink>
										</Link>
									</NavItem>
									<div className="nav-footer">
										<NavItem>
											<NavLink onMouseDown={this.logout}>
												Logout
											</NavLink>
										</NavItem>
									</div>
								</div>
							) : (
								<div className="nav-footer">
									<NavItem>
										<Link to="/login/">
											<NavLink>Log In</NavLink>
										</Link>
									</NavItem>
									<NavItem>
										<Link to="/signup/">
											<NavLink>Sign Up</NavLink>
										</Link>
									</NavItem>
								</div>
							)}
						</Nav>
					</Collapse>
				</Navbar>
			</div>
		);
	}
}

export default Navigation;
