import React from 'react';
import './notFound.scss';

export default function NotFound() {
	return (
		<div className="not-found">
			<div className="header">Oops!</div>
			<div className="error-code">404</div>
			<div className="error-message">
				Sorry, couldn't find what you're looking for
			</div>
		</div>
	);
}
