import React, { Component } from 'react';
import solo from '../../assets/WizardForm/solo.jpg';
import couple from '../../assets/WizardForm/couple.jpg';
import family from '../../assets/WizardForm/Family.jpg';
import group from '../../assets/WizardForm/group.jpg';
import './step3.scss';

export const NUM_TRAVELERS_OPTIONS = {
	solo: {
		description:
			'A trip out with a RedKnot is a great way to explore a city as a Solo traveler. Your trip will be private to you and take you wherever you want to go. It’s the best way to get to know a city or a region!',
		img: solo,
		inputs: false,
		adults: 1,
		kids: 0
	},
	couple: {
		description:
			'RedKnot Ambassadors can plan an experience that accommodates both of your interests, your trip will be private to the two of you!',
		img: couple,
		inputs: false,
		adults: 2,
		kids: 0
	},
	family: {
		description:
			'Our RedKnot Ambassadors love traveling with families and can design an experience that your whole family will enjoy. A standard RedKnot experience is great for a family of up to 4. Let us know if your family is larger.',
		img: family,
		inputs: true,
		adults: 1,
		kids: 0
	},
	group: {
		description:
			'A RedKnot experience is a great way for you and your group to get to know your destination. A standard RedKnot experience is great for a group of up to 4 although we can accommodate bigger groups if you contact us!',
		img: group,
		inputs: true,
		adults: 1,
		kids: 0
	}
};

export default class Step3 extends Component {
	constructor(props) {
		super();
	}

	setSolo = () => {
		this.props.handleChange({
			name: 'adults',
			value: 1
		});
		this.props.handleChange({
			name: 'kids',
			value: 0
		});
		this.props.handleChange({
			name: 'numTravelers',
			value: 1
		});
	};

	setCouple = () => {
		this.props.handleChange({
			name: 'adults',
			value: 2
		});
		this.props.handleChange({
			name: 'kids',
			value: 0
		});
		this.props.handleChange({
			name: 'numTravelers',
			value: 2
		});
	};

	selectOption = option => {
		switch (option) {
			case 'solo':
				this.setSolo();
				break;
			case 'couple':
				this.setCouple();
				break;
			default:
				break;
		}
		this.props.handleChange({
			name: 'option',
			value: option
		});
	};

	increaseAdults = () => {
		let adults = parseInt(this.props.adults) || 0;
		const kids = parseInt(this.props.kids) || 0;
		const numTravelers = adults + kids;
		if (numTravelers > 13) {
			return;
		}
		adults += 1;
		this.props.handleChange({
			name: 'adults',
			value: adults
		});
		this.props.handleChange({
			name: 'numTravelers',
			value: numTravelers + 1
		});
	};

	increaseKids = () => {
		let kids = parseInt(this.props.kids) || 0;
		const adults = parseInt(this.props.adults) || 0;
		const numTravelers = adults + kids;
		if (numTravelers > 13) {
			return;
		}
		kids += 1;
		this.props.handleChange({
			name: 'kids',
			value: kids
		});
		this.props.handleChange({
			name: 'numTravelers',
			value: numTravelers + 1
		});
	};

	decreaseAdults = () => {
		let adults = parseInt(this.props.adults) || 1;
		const kids = parseInt(this.props.kids) || 0;
		const numTravelers = adults + kids;
		if (adults === 1) {
			return;
		}
		adults -= 1;
		this.props.handleChange({
			name: 'adults',
			value: adults
		});
		this.props.handleChange({
			name: 'numTravelers',
			value: numTravelers - 1
		});
	};

	decreaseKids = () => {
		let kids = parseInt(this.props.kids) || 0;
		const adults = parseInt(this.props.adults) || 0;
		const numTravelers = adults + kids;
		if (kids === 0) {
			return;
		}
		kids -= 1;
		this.props.handleChange({
			name: 'kids',
			value: kids
		});
		this.props.handleChange({
			name: 'numTravelers',
			value: numTravelers - 1
		});
	};

	render() {
		const { option, adults, kids } = this.props;
		const optionKeys = Object.keys(NUM_TRAVELERS_OPTIONS);

		return (
			<div id="step-3" className="main-container">
				<div className="main-title-container">
					<div className="step-container">
						<div className="step">
							<span>2</span>
						</div>
					</div>
					<div className="title-container">
						<h2>Number of people going</h2>
					</div>
				</div>
				<div className="number-people-container">
					{optionKeys.map(key => {
						const opt = NUM_TRAVELERS_OPTIONS[key];
						return (
							<>
								<div
									className={
										option === ''
											? 'number-people-box'
											: option === key
											? 'number-people-box active'
											: 'number-people-box inactive'
									}
									style={{
										backgroundImage: 'url(' + opt.img + ')'
									}}
									onClick={() => {
										this.selectOption(key);
									}}
								>
									<span>{key}</span>
								</div>
								<div
									className={
										option === key
											? 'description active'
											: 'description'
									}
								>
									{opt.inputs && (
										<div className="inputs-container">
											<div className="number-input">
												<span
													className="btn-decrease"
													onClick={() =>
														this.decreaseAdults()
													}
												>
													-
												</span>
												<input
													type="number"
													placeholder="Adults"
													name="adults"
													value={adults || ''}
													className="num-travelers"
													disabled
												></input>
												<span
													className="btn-increase"
													onClick={() =>
														this.increaseAdults()
													}
												>
													+
												</span>
											</div>
											<div className="number-input">
												<span
													className="btn-decrease"
													onClick={() =>
														this.decreaseKids()
													}
												>
													-
												</span>
												<input
													type="number"
													placeholder="Kids"
													name="kids"
													value={kids || ''}
													className="num-travelers"
													disabled
												></input>
												<span
													className="btn-increase"
													onClick={() =>
														this.increaseKids()
													}
												>
													+
												</span>
											</div>
										</div>
									)}
									{opt.description}
								</div>
							</>
						);
					})}
				</div>
			</div>
		);
	}
}
