import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { isEmpty, pickBy, intersection } from 'lodash';

import RedknotVerified from '../../assets/Redknot-verified.png';
import lang from '../../assets/lang.svg';
import prof from '../../assets/prof.svg';
import './resultCard.scss';

const ResultCard = ({
	allInterests,
	currentPage,
	dates,
	result,
	adults,
	kids,
	conversion,
	history,
	index,
	interests
}) => {
	const getSelectedInterests = () => {
		const selected = pickBy(interests, interest => {
			return interest.selected;
		});
		return selected;
	};

	const getTopInterests = scores => {
		const keys = Object.keys(scores);
		const sorted = keys.sort((a, b) => {
			return scores[a] - scores[b];
		});
		let top = [];
		// TODO: ensure 32 is a good top mark
		// (if all categories with a score of 32 or higher are considered top)
		const TOP_MARK = 32;

		let score;
		sorted.forEach(cat => {
			score = scores[cat];
			if (score >= TOP_MARK) top.push(cat);
		});

		return top;
	};

	const getCommonInterests = categoryScores => {
		const interests = getSelectedInterests();
		const interestsKeys = Object.keys(interests);
		const topInterests = getTopInterests(categoryScores);
		const common = intersection(topInterests, interestsKeys);
		return common;
	};

	const {
		photo,
		firstName,
		languages,
		tagline,
		uid,
		categoryScores
	} = result;
	const tariff = parseInt(result.tariff);
	const numTravelers = adults + kids;
	const perDayPrice = Math.trunc((tariff + conversion) / numTravelers);

	let didSelectInterests = !isEmpty(interests);
	let top = getTopInterests(categoryScores);
	let interestsList = top;

	let common = getCommonInterests(categoryScores);
	if (didSelectInterests && !isEmpty(common)) {
		interestsList = common;
	}

	return (
		<div className="ambassador-result-card" key={index}>
			<div className="card-photo-container">
				{index === 0 && currentPage === 1 && (
					<div id="top-score-badge">
						<FontAwesomeIcon icon={faStar}></FontAwesomeIcon>
						<div>TOP MATCH</div>
					</div>
				)}
				<div
					className="photo-profile"
					onClick={() =>
						history.push({
							pathname: '/user/' + uid,
							search: !isEmpty(dates) ? '?d=' + dates : '',
							state: {
								adults,
								kids,
								conversion
							}
						})
					}
					style={{
						backgroundImage:
							'linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 100%), url(' +
							photo +
							')'
					}}
				></div>
			</div>
			<div className="card-info-body">
				<div className="card-info">
					<div className="card-headline">
						<span>{firstName}</span>
						<div className="redknot-verified-badge">
							<img
								src={RedknotVerified}
								alt="redknot-verified"
							></img>
							<span>Redknot Verified</span>
						</div>
					</div>
					<div className="card-data">
						{languages && (
							<div className="info-box">
								<span>
									<img src={lang} alt="languages" />
									<div>{languages}</div>
								</span>
							</div>
						)}
						{tagline && (
							<div className="info-box">
								<span>
									<img src={prof} alt="headline" />
									<div>{tagline}</div>
								</span>
							</div>
						)}
					</div>
				</div>
				{!isEmpty(allInterests) && (
					<div className="interests-container">
						{!isEmpty(common) ? (
							<span>Common interests with {firstName}:</span>
						) : (
							<span>{firstName}'s top interests:</span>
						)}
						<p>
							{interestsList.map((key, idx) => {
								if (allInterests[key]) {
									let name = allInterests[key].name;
									return (
										<span>
											{name}
											{idx !== interestsList.length - 1 &&
												', '}
										</span>
									);
								}
								return null;
							})}
						</p>
					</div>
				)}
			</div>
			<div className="card-info-footer">
				<div className="pricing">
					<div className="price-total">
						<span>
							USD{' $'}
							{tariff + conversion}
						</span>
						<div className="price-detail">
							(${perDayPrice} per person) per day
						</div>
					</div>
				</div>
				<button
					className="button-primary"
					onClick={() => {
						history.push({
							pathname: '/user/' + uid,
							search: !isEmpty(dates) ? '?d=' + dates : '',
							state: {
								adults,
								kids,
								conversion
							}
						});
					}}
				>
					Explore with {firstName}
				</button>
			</div>
		</div>
	);
};

export default ResultCard;
