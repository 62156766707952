import React, { Component } from 'react';
import { injectStripe } from 'react-stripe-elements';
import moment from 'moment';
import { isEmpty } from 'lodash';

import config from '../../config';
import Dispatcher from '../../common/dispatcher/index';
import EventTypes from '../../common/events';
import ActionTypes from '../../common/actions';
import UserStore from '../../common/stores/user';
import Mailer from '../../common/mailer';
import ReactPixel from '../../common/FbPixel';

import {
	BOOKING_DB_FORMAT,
	ITINERARY_DB_FORMAT,
	ITINERARY_EMAIL_FORMAT
} from '../../consts/DateFormats';

class CheckoutForm extends Component {
	constructor(props) {
		super(props);
		const bookingId = props.match.params.id;
		const booking = UserStore.getBooking();
		this.state = {
			bookingId,
			complete: booking.status === 'completed',
			error: false,
			booking,
			user: UserStore.getUser(),
			ambassador: booking.ambassador
		};

		// TODO: get booking only when store and queryparam bookingIds are different
		Dispatcher.dispatch({
			actionType: ActionTypes.GET_BOOKING,
			data: bookingId
		});
	}

	componentDidMount() {
		UserStore.addListener(EventTypes.BOOKING_LOADED, this.onBookingLoaded);
		UserStore.addListener(
			EventTypes.BOOKING_UPDATED,
			this.onBookingUpdated
		);
		UserStore.addListener(
			EventTypes.ITINERARY_CREATED,
			this.onItineraryCreated
		);
		UserStore.addListener(
			EventTypes.AVAILABILITY_UPDATED,
			this.onAvailabilityUpdated
		);
	}

	componentWillUnmount() {
		UserStore.removeListener(
			EventTypes.BOOKING_LOADED,
			this.onBookingLoaded
		);
		UserStore.removeListener(
			EventTypes.BOOKING_UPDATED,
			this.onBookingUpdated
		);
		UserStore.removeListener(
			EventTypes.ITINERARY_CREATED,
			this.onItineraryCreated
		);
		UserStore.removeListener(
			EventTypes.AVAILABILITY_UPDATED,
			this.onAvailabilityUpdated
		);
	}

	onBookingLoaded = () => {
		const booking = UserStore.getBooking();
		// TODO: find a better way to only save certain fields of the booking
		// (the API returns the whole ambassador and we don't want to save it to the DB)
		let {
			ambassadorId,
			ambassador,
			travelerId,
			agentId,
			itineraryId,
			dates,
			price,
			status,
			city,
			couponId,
			numAdults,
			numChildren
		} = booking;

		// console.log('purchase completed with status' + booking.status);
		if (status !== 'completed') return;

		const { user } = this.state;

		let itineraryDays = {};
		let datesString = '';
		dates.forEach((date, idx) => {
			if (idx === dates.length - 1) {
				datesString += ' and ';
			} else if (idx !== 0) {
				datesString += ', ';
			}
			itineraryDays[
				moment(date, BOOKING_DB_FORMAT).format(ITINERARY_DB_FORMAT)
			] = {};
			datesString += moment(date, BOOKING_DB_FORMAT)
				.format(ITINERARY_EMAIL_FORMAT)
				.toString();
		});

		this.setState({
			booking: {
				ambassadorId,
				travelerId,
				itineraryId,
				dates,
				price,
				status,
				city,
				couponId
			},
			ambassador,
			datesString,
			complete: booking.status === 'completed',
			error: booking.status !== 'completed'
		});

		// if booking has email, means it was brought by a concierce user
		if (user.isAgent) {
			travelerId = booking.travelerId;
			agentId = booking.agentId;
		} else {
			travelerId = user.uid;
		}
		Dispatcher.dispatch({
			actionType: ActionTypes.CREATE_ITINERARY,
			data: {
				ambassadorId: booking.ambassadorId,
				travelerId,
				agentId,
				bookingId: booking.id,
				city: booking.city,
				days: itineraryDays,
				travelerName: user.firstName,
				ambassadorName: ambassador.firstName,
				numAdults,
				numChildren
			}
		});
	};

	onItineraryCreated = () => {
		const { user, booking } = this.state;
		const itinerary = UserStore.getItinerary();

		if (booking.status === 'completed') {
			this.setState({ complete: true });
			booking['itineraryId'] = itinerary.id;
			booking['travelerId'] = user.uid;
			booking['completedOn'] = new Date();
			Dispatcher.dispatch({
				actionType: ActionTypes.UPDATE_BOOKING,
				data: {
					booking,
					id: this.state.bookingId
				}
			});
		} else {
			this.setState({ complete: false, error: true });
		}
	};

	onBookingUpdated = () => {
		const { user, ambassador, datesString } = this.state;
		const booking = UserStore.getBooking();

		this.setState({
			booking
		});

		// update amabassador's availability
		Dispatcher.dispatch({
			actionType: ActionTypes.UPDATE_AVAILABILITY,
			data: {
				uid: booking.ambassadorId,
				dates: booking.dates
			}
		});

		// only send when in production
		if (config.isProd) {
			// send mail to traveler
			Mailer.sendTravelerPurchaseConfirmation(
				user.firstName,
				user.email,
				ambassador.firstName,
				booking.city,
				datesString
			);

			// send mail to ambassador
			Mailer.sendAmbassadorPurchaseConfirmation(
				ambassador.firstName,
				ambassador.email,
				user.firstName,
				booking.city,
				booking.itineraryId,
				datesString
			);
		}
	};

	onAvailabilityUpdated = () => {
		const itinerary = UserStore.getItinerary();
		ReactPixel.pageView();
		ReactPixel.fbq('track', 'Purchase');
		this.props.history.push('/trips/' + itinerary.id);
	};

	render() {
		const { booking } = this.state;
		if (isEmpty(booking)) return null;

		if (this.state.complete) {
			return (
				<div>
					<h1>Purchase Complete</h1>
					<button
						className="button-primary"
						style={{ marginTop: 30 }}
						onClick={() =>
							this.props.history.push(
								'/trips/' + booking.itineraryId
							)
						}
					>
						View Trip
					</button>
				</div>
			);
		}
		if (this.state.error) {
			return (
				<div>
					<h1>There was an error processing your payment</h1>
					<h4>
						Please contact us at bridges@goredknot.com to help you
						with this issue
					</h4>
				</div>
			);
		}
	}
}

export default injectStripe(CheckoutForm);
