import React from 'react';
import ReactDOM from 'react-dom';

import * as serviceWorker from './serviceWorker';
import { firebaseApp } from './firebase';
import App from './App';
import Dispatcher from './common/dispatcher/index';
import ActionTypes from './common/actions';

import '../node_modules/bootstrap/dist/css/bootstrap.min.css';

firebaseApp.auth().onAuthStateChanged(firebaseUser => {
	if (!firebaseUser) {
		Dispatcher.dispatch({
			actionType: ActionTypes.USER_AUTHENTICATED,
			data: {
				user: null,
				idToken: {
					token: ''
				}
			}
		});
		return;
	}
	firebaseUser
		.getIdTokenResult(true)
		.then(token => {
			Dispatcher.dispatch({
				actionType: ActionTypes.USER_AUTHENTICATED,
				data: {
					user: firebaseUser,
					idToken: token
				}
			});
		})
		.catch(e => console.error(e));
});

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
