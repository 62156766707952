import React, { Component } from 'react';
import { Progress } from 'reactstrap';
import { isEmpty, filter, take, chunk, union } from 'lodash';
import { Tooltip } from 'reactstrap';
import MultipleDatePicker from 'react-multiple-datepicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faMapMarkerAlt,
	faLanguage,
	faBriefcase
} from '@fortawesome/free-solid-svg-icons';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import {
	FacebookShareButton,
	FacebookIcon,
	WhatsappShareButton,
	WhatsappIcon,
	TwitterShareButton,
	TwitterIcon
} from 'react-share';
import moment from 'moment';

import Dispatcher from '../../common/dispatcher/index';
import UserStore from '../../common/stores/user';
import ActionTypes from '../../common/actions';
import EventTypes from '../../common/events';
import { Interviewers } from '../../consts/Interviewers';

import BtnLoader from '../utils/BtnLoader';
import { Loading } from '../utils/Loading';
import AlertMsg from '../utils/Alert';
import Modal from '../utils/Modal';
import GalleryModal from '../profile/GalleryModal';
import { GroupSizeHandler } from '../blocks/GroupSizeHandler';

import {
	cleanUrlToShare,
	readMore,
	getAvailability,
	getUrlParameters
} from '../../utils/Validation';
import { getDateRange, toDate, isValidDate, toMoment } from '../../utils/Dates';

import { categoryIcons } from '../../consts/Icons';
import { InterestList } from '../../consts/InterestList';

import aboutMeSvg from '../../assets/001-compass.svg';
import aroundSvg from '../../assets/002-around.svg';
import RedknotV from '../../assets/RedKnot_Verified.svg';

import user from '../../assets/003-user.svg';
import carAsset from '../../assets/carIcon.svg';
import suvAsset from '../../assets/SUV1.png';
import vanAsset from '../../assets/SUV1.png';

import {
	BOOKING_DB_FORMAT,
	EXAMPLE_DAY_FORMAT
} from '../../consts/DateFormats';

import './headerGallery.scss';
import './user.scss';

class User extends Component {
	constructor(props) {
		super();
		window.scrollTo({ top: 0, behavior: 'smooth' });
		const uid = props.match.params.uid;
		const param = props.history.location.search;
		const profile = UserStore.getUserProfile();
		const isAmbassador = UserStore.isAmbassador();
		let numAdults = 3;
		let numChildren = 0;
		let conversion = 0;
		let priceIncrease = 0;
		let dates = [];

		if (props.location.state) {
			numAdults = props.location.state.adults;
			numChildren = props.location.state.kids;
			conversion = props.location.state.conversion;
			// dates = props.location.state.dates;
		}

		if (!isEmpty(param)) {
			const urlParams = getUrlParameters();
			priceIncrease = urlParams.code
				? parseInt(window.atob(urlParams.code))
				: 0;
			dates = urlParams.d.split(',');
		}

		let areDatesValid = true;
		dates.map(date => {
			areDatesValid = areDatesValid && isValidDate(date);
		});

		dates = areDatesValid ? dates : [];

		// COVID specific
		const apr1 = '2020-04-01';
		const oct31 = '2020-10-31';
		const covidDates = getDateRange(apr1, oct31);

		this.state = {
			profile,
			dates,
			isGalleryOpen: false,
			isModalOpen: false,
			activePhoto: 0,
			isAmbassador,
			maxLength: 280,
			title: null,
			body: null,
			requestLoading: false,
			tooltipOpen: false,
			numAdults,
			numChildren,
			conversion,
			priceIncrease,
			validBooking: true,
			covidDates
		};

		Dispatcher.dispatch({
			actionType: ActionTypes.GET_USER_PROFILE,
			data: uid
		});
	}

	componentDidMount() {
		UserStore.addListener(EventTypes.PROFILE_LOADED, this.onProfileLoaded);
		UserStore.addListener(
			EventTypes.BOOKING_CREATED,
			this.onBookingCreated
		);
	}

	componentWillUnmount() {
		UserStore.removeListener(
			EventTypes.PROFILE_LOADED,
			this.onProfileLoaded
		);
		UserStore.removeListener(
			EventTypes.BOOKING_CREATED,
			this.onBookingCreated
		);
	}

	onProfileLoaded = () => {
		this.setState({
			profile: UserStore.getUserProfile()
		});
	};

	requestBooking = () => {
		const {
			profile,
			dates,
			numAdults,
			numChildren,
			conversion,
			priceIncrease
		} = this.state;

		const tariff = parseInt(profile.tariff);
		const price = (tariff + conversion + priceIncrease) * dates.length;

		this.setState({
			requestLoading: true
		});

		Dispatcher.dispatch({
			actionType: ActionTypes.CREATE_BOOKING,
			data: {
				ambassadorId: profile.uid,
				price,
				dates,
				status: 'requested',
				itineraryId: null,
				numAdults,
				numChildren,
				conversion
			}
		});
	};

	onBookingCreated = () => {
		const booking = UserStore.getBooking();
		this.props.history.push('/booking/' + booking.id);
	};

	openGallery = activePhoto => {
		this.setState({
			isGalleryOpen: true,
			activePhoto
		});
	};

	openModal = (title, body) => {
		// For now, two full stops represent line breaks
		body = body.replace(/\.\./g, '.\n\n');

		this.setState({
			isModalOpen: true,
			title,
			body
		});
	};

	toggleGallery = () => {
		this.setState({
			isGalleryOpen: !this.state.isGalleryOpen
		});
	};

	toggleModal = () => {
		this.setState({
			isModalOpen: !this.state.isModalOpen
		});
	};

	toggleToolTip = targetName => {
		if (!this.state[targetName]) {
			this.setState({
				...this.state,
				[targetName]: {
					tooltipOpen: true
				}
			});
		} else {
			this.setState({
				...this.state,
				[targetName]: {
					tooltipOpen: !this.state[targetName].tooltipOpen
				}
			});
		}
	};

	isToolTipOpen = targetName => {
		return this.state[targetName]
			? this.state[targetName].tooltipOpen
			: false;
	};

	handleDatesInput(dates) {
		let formattedDates = toMoment(dates, BOOKING_DB_FORMAT);
		this.setState({
			dates: formattedDates
		});
	}

	handleTravelers = ({ numAdults, numChildren, conversion }) => {
		this.setState({
			numAdults,
			numChildren,
			conversion
		});
	};

	checkTripisAvailable = status => {
		this.setState({
			validBooking: status
		});
	};

	render() {
		const {
			profile,
			dates,
			isGalleryOpen,
			activePhoto,
			maxLength,
			isModalOpen,
			title,
			body,
			requestLoading,
			conversion,
			priceIncrease,
			numAdults,
			numChildren,
			validBooking,
			covidDates
		} = this.state;

		if (!profile) {
			return <Loading></Loading>;
		}

		const {
			firstName,
			photo,
			bio,
			country,
			city,
			lifestylePics,
			categoryScores,
			languages,
			tagline,
			carType,
			fav,
			places,
			exampleDay,
			interviewBy
		} = profile;

		const tariff = parseInt(profile.tariff);
		const price = tariff + conversion + priceIncrease;
		const numTravelers = numAdults + numChildren;
		const perDayPrice = Math.trunc(price / numTravelers);
		const { availableDates, unavailableDates } = getAvailability(
			this.state.profile.availability
		);

		const disabledDates = union(unavailableDates, covidDates);
		const formattedDates = toDate(dates);

		const catKeys = Object.keys(categoryScores);
		// filter by currently used categories
		const filteredKeys = filter(catKeys, key => {
			return categoryIcons[key];
		});

		// order by score desc
		filteredKeys.sort((k1, k2) => {
			return categoryScores[k2] - categoryScores[k1];
		});

		// take only 6 and split into two groups for easier display
		const categoryIds = take(filteredKeys, 6);
		const splitCategories = chunk(categoryIds, 3);
		const primary = splitCategories[0];
		const secondary = splitCategories[1];

		let dayTimes;
		if (!isEmpty(exampleDay)) {
			dayTimes = Object.keys(exampleDay);
			// order example day times
			dayTimes.sort((t1, t2) => {
				return (
					moment(t1, EXAMPLE_DAY_FORMAT) -
					moment(t2, EXAMPLE_DAY_FORMAT)
				);
			});
		}

		let carCapacity;
		let carImg;
		switch (carType) {
			case 'car':
				carCapacity = 4;
				carImg = carAsset;
				break;
			case 'suv':
				carCapacity = 7;
				carImg = suvAsset;
				break;
			case 'van':
				carCapacity = 10;
				carImg = vanAsset;
				break;
			default:
				break;
		}
		let urlToShare =
			cleanUrlToShare(window.location.href) || window.location.href;

		return (
			<>
				{!isEmpty(lifestylePics) && (
					<Carousel
						autoPlay
						showThumbs={false}
						infiniteLoop={true}
						onClickItem={this.openGallery}
						centerMode
					>
						{lifestylePics.map((pic, index) => {
							return (
								<div
									key={index}
									className="carousel-container"
									style={{
										backgroundImage: 'url(' + pic.url + ')'
									}}
								></div>
							);
						})}
					</Carousel>
				)}
				<div id="profile-view-user">
					<div className="ambassador-info-container">
						<div className="ambassador-personal-container">
							<div
								onClick={() => {
									this.openModal(null, photo);
								}}
								className="profile-picture-ambassador"
								style={{
									backgroundImage: 'url(' + photo + ')'
								}}
							>
								<div
									className="redknot-logo-verified"
									style={{
										backgroundImage: 'url(' + RedknotV + ')'
									}}
								></div>
							</div>

							<div className="profile-desc">
								<span
									className="name"
									style={{ display: 'flex' }}
								>
									{firstName}
								</span>
								<div className="info-container">
									<div className="info-ambassador-box">
										<span
											id="amb-city"
											className="ambassador-span"
										>
											<span className="ambassador-span-icon">
												<FontAwesomeIcon
													icon={faMapMarkerAlt}
													style={{
														color: '#EA1C2D'
													}}
													size={'lg'}
												/>
											</span>
											<span>{city}</span>
										</span>

										<Tooltip
											placement="top"
											isOpen={this.isToolTipOpen(
												'amb-city'
											)}
											target="amb-city"
											toggle={() =>
												this.toggleToolTip('amb-city')
											}
										>
											City
										</Tooltip>
									</div>
									<div className="info-ambassador-box">
										<span
											id="amb-lang"
											className="ambassador-span"
										>
											<span className="ambassador-span-icon">
												<FontAwesomeIcon
													icon={faLanguage}
													style={{
														color: '#EA1C2D'
													}}
													size={'lg'}
												/>
											</span>
											<span>{languages}</span>
										</span>

										<Tooltip
											placement="top"
											isOpen={this.isToolTipOpen(
												'amb-lang'
											)}
											target="amb-lang"
											toggle={() =>
												this.toggleToolTip('amb-lang')
											}
										>
											Languages
										</Tooltip>
									</div>
									<div className="info-ambassador-box">
										<span
											id="amb-headline"
											className="ambassador-span"
										>
											<span className="ambassador-span-icon">
												<FontAwesomeIcon
													icon={faBriefcase}
													style={{
														color: '#EA1C2D'
													}}
													size={'lg'}
												/>
											</span>
											<span>{tagline}</span>
										</span>

										<Tooltip
											placement="top"
											isOpen={this.isToolTipOpen(
												'amb-headline'
											)}
											target="amb-headline"
											toggle={() =>
												this.toggleToolTip(
													'amb-headline'
												)
											}
										>
											Work & Studies
										</Tooltip>
									</div>
								</div>
							</div>
						</div>
						<div className="main-interest">
							<span className="main-title">
								Things I am passionate about
							</span>
							<div className="container-interest">
								<div className="container-left">
									{!isEmpty(primary) &&
										primary.map(id => {
											const value = (
												(categoryScores[id] / 50) *
												100
											).toFixed();
											return (
												<div
													className="info-ambassador-box"
													id={id}
												>
													<span className="ambassador-span-icon">
														<FontAwesomeIcon
															icon={
																categoryIcons[
																	id
																]
															}
															style={{
																marginRight: 2,
																color: '#EA1C2D'
															}}
															size={'lg'}
														/>
													</span>
													<Progress
														color="danger"
														value={value}
														max="100"
													/>

													<Tooltip
														placement="top"
														isOpen={this.isToolTipOpen(
															id
														)}
														target={id}
														toggle={() =>
															this.toggleToolTip(
																id
															)
														}
													>
														{InterestList[id]}
													</Tooltip>
												</div>
											);
										})}
								</div>
								<div className="container-right">
									{!isEmpty(secondary) &&
										secondary.map(id => {
											const value = (
												(categoryScores[id] / 50) *
												100
											).toFixed();
											return (
												<div
													className="info-ambassador-box"
													id={id}
												>
													<span className="ambassador-span-icon">
														<FontAwesomeIcon
															icon={
																categoryIcons[
																	id
																]
															}
															style={{
																marginRight: 2,
																color: '#EA1C2D'
															}}
															size={'lg'}
														/>
													</span>
													<Progress
														color="danger"
														value={value}
														max="100"
													/>

													<Tooltip
														placement="top"
														isOpen={this.isToolTipOpen(
															id
														)}
														target={id}
														toggle={() =>
															this.toggleToolTip(
																id
															)
														}
													>
														{InterestList[id]}
													</Tooltip>
												</div>
											);
										})}
								</div>
							</div>
						</div>
						<div className="car-verified-container">
							<div className="car-container">
								<div className="car-title-container">
									<span className="car-container-title">
										Vehicle
									</span>
								</div>
								<div className="car-image-container">
									<img src={carImg} alt="car" />
								</div>
								<div className="car-footer">
									<div className="car-container-footer">
										{carType}
									</div>
									<div className="car-container-footer">
										(max {carCapacity} people)
									</div>
								</div>
							</div>
							{interviewBy && (
								<div className="interview-text">
									<a
										href="https://www.goredknot.com/about-us"
										target="_blank"
										rel="noopener noreferrer"
									>
										<div>
											INTERVIEWED BY<br></br>
											<span>
												<b>
													{interviewBy.name === 'RK'
														? 'RedKnot HQ Team'
														: interviewBy.name}
												</b>
											</span>
										</div>
									</a>
									<a
										href="https://www.goredknot.com/about-us"
										target="_blank"
										rel="noopener noreferrer"
									>
										<div
											style={{
												height: 30,
												width: 30,
												marginLeft: 10,
												borderRadius: '25px',
												backgroundPosition: 'center',
												cursor: 'pointer',
												backgroundSize: 'cover',
												backgroundImage:
													'url(' +
													Interviewers[
														interviewBy.name
													] +
													')'
											}}
										></div>
									</a>
								</div>
							)}
						</div>
					</div>
					<div className="ambassador-personal-info">
						<div className="about-me-container">
							<div className="about-me-img-container">
								<img alt={''} src={user} />
							</div>
							<div className="about-me-text-container">
								<h5 className="about-me-title">About me</h5>
								{readMore(bio, maxLength)}
								{bio.length > maxLength && (
									<button
										onClick={() => {
											this.openModal(firstName, bio);
										}}
										className="readBtn"
									>
										Read more
									</button>
								)}
							</div>
						</div>
						{fav && (
							<div className="about-me-container">
								<div className="about-me-img-container">
									<img alt={''} src={aboutMeSvg} />
								</div>
								<div className="about-me-text-container">
									<h5 className="about-me-title">
										My favorite things to do in my country
									</h5>
									{readMore(fav, maxLength)}
									{fav.length > maxLength && (
										<button
											onClick={() => {
												this.openModal(
													'My favorite things to do in my country',
													fav
												);
											}}
											className="readBtn"
										>
											Read more
										</button>
									)}
								</div>
							</div>
						)}
						{places && (
							<div className="about-me-container">
								<div className="about-me-img-container">
									<img alt={''} src={aroundSvg} />
								</div>
								<div className="about-me-text-container">
									<h5 className="about-me-title">
										Places I’ve been
									</h5>
									{readMore(places, maxLength)}
									{places.length > maxLength ? (
										<button
											onClick={() => {
												this.openModal(
													'Places I’ve been',
													places
												);
											}}
											className="readBtn"
										>
											Read more
										</button>
									) : null}
								</div>
							</div>
						)}
					</div>
					{dayTimes && (
						<div id="perfect-day-container">
							<h5 className="perfect-day-title">
								An example of what a day with me could look like
								<div>
									{firstName} will create an itinerary just
									for you
								</div>
							</h5>
							<div id="timeline-container">
								<ul className="timeline">
									{dayTimes.map(time => {
										return (
											<li className="timeline__step">
												<div className="timeline__title">
													<p className="timeline-hour">
														{time}
													</p>
													{exampleDay[time]}
												</div>
											</li>
										);
									})}
								</ul>
							</div>
						</div>
					)}
					<div id="share-container">
						<p>
							<b>{`Share ${profile.firstName}'s profile`}</b>
						</p>
						<FacebookShareButton url={urlToShare}>
							<FacebookIcon size={32} round={true} />
						</FacebookShareButton>
						<WhatsappShareButton url={urlToShare}>
							<WhatsappIcon size={32} round={true} />
						</WhatsappShareButton>
						<TwitterShareButton url={urlToShare}>
							<TwitterIcon size={32} round={true} />
						</TwitterShareButton>
					</div>

					<div id="footer-container">
						<div className="tariff" id="amount">
							<span className="amount">USD $ {price} </span>
							<span className="price-per-person">
								(${perDayPrice} per person) per day
							</span>

							<Tooltip
								placement="top"
								isOpen={this.isToolTipOpen()}
								target="amount"
								toggle={() => this.toggleToolTip()}
							>
								Includes: 6-8 hours of guided travel, a flexible
								and personalized itinerary, invaluable local
								knowledge and all transfers
							</Tooltip>
						</div>
						<GroupSizeHandler
							numAdults={numAdults}
							numChildren={numChildren}
							carType={carType}
							price={price}
							country={country}
							oldConversion={conversion}
							handleTravelers={this.handleTravelers}
							checkTripisAvailable={this.checkTripisAvailable}
						></GroupSizeHandler>
						{!validBooking && (
							<div className="alert-text">
								We are sorry but this ambassador's car cannot
								fit that many travelers. Please chat with us and
								we can make alternative arrangements.
							</div>
						)}
						<div className="datepicker-container">
							{!isEmpty(dates) && validBooking ? (
								<>
									<div onClick={this.requestBooking}>
										<BtnLoader
											loading={requestLoading}
											text={`Book time with ${profile.firstName}`}
										/>
									</div>
									<MultipleDatePicker
										className="custom-datepicker white-scheme"
										minDate={new Date()}
										onSubmit={dates =>
											this.handleDatesInput(dates)
										}
										maxDate={
											new Date(
												Math.max.apply(
													null,
													availableDates
												)
											)
										}
										selectedDates={formattedDates}
										disabledDates={disabledDates}
									/>
								</>
							) : (
								validBooking && (
									<MultipleDatePicker
										className="custom-datepicker"
										minDate={new Date()}
										onSubmit={dates =>
											this.handleDatesInput(dates)
										}
										maxDate={
											new Date(
												Math.max.apply(
													null,
													availableDates
												)
											)
										}
										disabledDates={disabledDates}
									/>
								)
							)}
						</div>
						<div className="alerts-container">
							<AlertMsg
								content="To keep our community safe we have decided to suspend all RedKnot bookings until October."
								color="warning"
								isOpen={true}
							></AlertMsg>
						</div>
					</div>
					<GalleryModal
						isOpen={isGalleryOpen}
						gallery={lifestylePics}
						toggle={this.toggleGallery}
						selected={activePhoto}
					></GalleryModal>
					<Modal
						isOpen={isModalOpen}
						toggle={this.toggleModal}
						body={body}
						title={title}
					></Modal>
				</div>
			</>
		);
	}
}

export default User;
